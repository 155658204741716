import React, { useCallback, useEffect, useState } from 'react';
import { Waypoint, WaypointType } from '@/features/home';
import { useCommonItemMaps } from '@/hooks/useCommonItemMaps';
import { getGarbageIconLink } from '@/helpers/garbage';
import { useTranslation } from 'react-i18next';
import { ObjectServiceActivityType } from '@/types/objects';
import { conditionalClassNames } from '@/helpers/dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectArrivedPageForm,
  selectArrivedPagePhotosByWaypointId,
  setFormWaypointIdsForPhotoUpload,
  setIsTakePhotoModalVisible,
  setServiceWaypointDischarges,
  toggleSelectedWaypointId,
} from '@/features/route';
import CheckMark from '@/components/icons/CheckMark';
import CrossMark from '@/components/icons/CrossMark';
import PhotoIcon from '@/components/icons/PhotoIcon';
import GpsIcon from '@/components/icons/GpsIcon';
import { unifiedString } from '@/helpers/strings';
import Checkbox from '@/components/elements/Checkbox';
import NotificationBadgeWrapper from '@/components/elements/NotificationBadgeWrapper';
import ImageGalleryIcon from '@/components/icons/ImageGalleryIcon';
import ImagesModal from '@/components/modals/ImagesModal';
import useModal from '@/hooks/useModal';
import { selectIsCurrentRouteGroupItemOpenedMode } from '@/features/home';
import InstallContainerBadge from '@/components/elements/InstallContainerBadge';
import DeInstallContainerBadge from '@/components/elements/DeInstallContainerBadge';
import { useCoordinatesClickHandler } from '../../hooks/useCoordinatesClickHandler';
import Spinner from '@/components/elements/Spinner';
import { useArrivedPageMergePhotos } from '../../hooks/arrived/useArrivedPageMergePhotos';

interface ArrivedPageWaypointServiceItemProps {
  waypoint: Waypoint<WaypointType.Service>;
}

const getActivityTypeTranslationKey = (activityType: ObjectServiceActivityType) => {
  const key = Object.entries(ObjectServiceActivityType).find(([, value]) => value === activityType)?.[0];

  return `activityTypes.${key[0].toLowerCase() + key.slice(1)}`;
};

const ArrivedPageWaypointServiceItem = ({ waypoint }: ArrivedPageWaypointServiceItemProps) => {
  const [isDischarged, setIsDischarged] = useState(false);
  const dispatch = useDispatch();

  const { selectedWaypointIds } = useSelector(selectArrivedPageForm) || {};
  const isRouteGroupItemOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);

  const { itemTypesMap } = useCommonItemMaps();
  const { isVisible: isImageModalVisible, toggle: toggleImageModal } = useModal();
  const { t } = useTranslation('services');

  const waypointPhotoLogs = useSelector(selectArrivedPagePhotosByWaypointId(waypoint.id));
  const entityIds = React.useMemo(() => [waypoint.id], [waypoint.id]);

  const { mergedPhotos, isFetching: isServicePicturesFetching } = useArrivedPageMergePhotos(
    waypointPhotoLogs,
    entityIds,
    WaypointType.Service,
  );

  const {
    activityType,
    notes,
    objectItem: { itemTypeId, garbageId },
  } = waypoint;

  useEffect(() => {
    dispatch(setServiceWaypointDischarges({ waypointId: waypoint.id, value: isDischarged }));
  }, [isDischarged]);

  const isSelected = selectedWaypointIds?.includes(waypoint.id);
  const isDone = Boolean(waypoint.doneAt);
  const isFailed = Boolean(waypoint.isFailed);

  const handleItemClick = () => {
    if (isDone || isFailed || isRouteGroupItemOpenedMode) {
      return;
    }

    dispatch(toggleSelectedWaypointId(waypoint.id));
  };

  const { handleCoordinatesClick } = useCoordinatesClickHandler(waypoint);

  const getContainerState = () => {
    let key = '';

    waypoint.relatedServices.forEach(({ activityType, isUsed }) => {
      if (activityType === ObjectServiceActivityType.Sale) {
        key = isUsed ? 'containerState.saleNew' : 'containerState.saleUsed';
      }
    });

    if (key) {
      return t(key);
    }

    if (waypoint.activityType === ObjectServiceActivityType.Install) {
      key = waypoint.isUsed ? 'containerState.rentUsed' : 'containerState.rentNew';
      return t(key);
    }

    return '';
  };

  const relatedServiceActivitiesNames =
    waypoint.relatedServices?.reduce((names, { activityType }) => {
      if ([ObjectServiceActivityType.Sale, ObjectServiceActivityType.Wash].includes(activityType)) {
        return names;
      }

      return [...names, getActivityTypeTranslationKey(activityType)];
    }, []) || [];

  return (
    <div
      className={conditionalClassNames({
        'text-xl flex flex-col p-2 gap-2 justify-center bg-white dark:bg-gray-600 border-2 rounded dark:border-gray-500 shadow relative':
          true,
        'arrived-page__list-item--selected': isSelected,
        'arrived-page__list-item--done': isDone,
        'arrived-page__list-item--failed': isFailed,
      })}
      onClick={handleItemClick}
    >
      <div className="flex flex-col gap-4 md:flex-row md:items-center">
        <div
          className={
            'flex items-center gap-2 font-bold bg-gray-200 dark:bg-gray-400 py-2.5 md:py-1.5 px-2 rounded w-fit md:w-auto md:min-w-[6rem] text-center'
          }
        >
          {activityType === ObjectServiceActivityType.Install && <InstallContainerBadge />}
          {activityType === ObjectServiceActivityType.DeInstall && <DeInstallContainerBadge />}
          {t(getActivityTypeTranslationKey(activityType))}
        </div>
        <div className={'flex gap-4 items-center'}>
          <div className={''}>
            <img className={'rounded h-10 w-10 border'} src={getGarbageIconLink(garbageId)} alt="" />
          </div>
          <span>{itemTypesMap[itemTypeId]?.name}</span>
        </div>
      </div>
      <div className={'grid gap-2 md:grid-cols-[16rem_min(16rem)_16rem]'}>
        <div className={'flex flex-col justify-center'}>
          <span className={'font-bold text-base'}>{t('rentSale', { ns: 'arrivedPage' })}:</span>
          <span>{getContainerState() || '---'}</span>
        </div>
        <div className={'flex flex-col justify-center'}>
          <span className={'font-bold text-base'}>{t('additionalInformation', { ns: 'arrivedPage' })}:</span>
          <span>{unifiedString(relatedServiceActivitiesNames.map(t)) || '---'}</span>
        </div>
        {waypoint.activityType === ObjectServiceActivityType.DeInstall && (
          <Checkbox
            value={isDischarged}
            onChange={setIsDischarged}
            label={t('isDischarged', { ns: 'arrivedPage' })}
            id={`is-discharged-${waypoint.id}`}
          />
        )}
      </div>
      {notes && <div className={'bg-gray-200 dark:bg-gray-500 p-2 rounded'}>{notes}</div>}
      <div className={'arrived-page__list-item__icons absolute top-2 right-2 flex gap-4 items-center'}>
        {isServicePicturesFetching && (
          <NotificationBadgeWrapper notificationsAmount={0} size="sm">
            <Spinner size="sm" />
          </NotificationBadgeWrapper>
        )}
        {mergedPhotos?.length > 0 && (
          <div
            className="clickable"
            onClick={(event) => {
              event.stopPropagation();
              toggleImageModal();
            }}
          >
            <NotificationBadgeWrapper notificationsAmount={mergedPhotos.length} size="sm">
              <ImageGalleryIcon size="md" />
            </NotificationBadgeWrapper>
          </div>
        )}
        <div className="clickable" onClick={handleCoordinatesClick}>
          <GpsIcon size={'md'} />
        </div>
        <div
          className="clickable"
          onClick={(event) => {
            event.stopPropagation();
            if (isRouteGroupItemOpenedMode) {
              return;
            }
            dispatch(setIsTakePhotoModalVisible(true));
            dispatch(setFormWaypointIdsForPhotoUpload([waypoint.id]));
          }}
        >
          <PhotoIcon size={'md'} />
        </div>
        <div className={isSelected ? 'text-green-500' : ''}>
          {(isSelected || isDone) && <CheckMark size={'md'} />}
          {isFailed && <CrossMark size={'md'} />}
        </div>
      </div>

      <ImagesModal isVisible={isImageModalVisible} onClose={toggleImageModal} images={mergedPhotos || []} waypointId={waypoint.id} />
    </div>
  );
};

export default ArrivedPageWaypointServiceItem;
