import serviceWaypoints, {
  GetServiceWaypointPicturesPayload,
  UploadServiceWaypointPicturePayload,
  UploadServiceWaypointPictureResponseDto,
} from '@/services/service-waypoints.service';
import { baseApi, SERVICE_WAYPOINTS_KEY } from '@/store/baseApi';
import { GetServicePhotoResponseDto, ObjectServiceLog } from '@ekt-group/general-purpose-api-interfaces';

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [SERVICE_WAYPOINTS_KEY],
});

export const serviceWaypointsApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadServiceWaypointPicture: builder.mutation<UploadServiceWaypointPictureResponseDto[], UploadServiceWaypointPicturePayload>({
      queryFn: async (body) => {
        return serviceWaypoints.uploadServiceWaypointPicture(body);
      },
    }),
    getServiceWaypointPictures: builder.query<GetServicePhotoResponseDto[], GetServiceWaypointPicturesPayload>({
      queryFn: async ({ serviceIds }) => {
        return serviceWaypoints.getServiceWaypointPictures({ serviceIds });
      },
    }),
    getServiceLogs: builder.query<ObjectServiceLog[], { serviceIds: number[] }>({
      query: (params) => ({
        url: '/objects/services/logs/photos',
        method: 'GET',
        params: {
          serviceIds: params.serviceIds.join(','),
        },
      }),
    }),
  }),
});

export const {
  useUploadServiceWaypointPictureMutation,
  useLazyGetServiceWaypointPicturesQuery,
  useGetServiceLogsQuery,
  useGetServiceWaypointPicturesQuery,
  useLazyGetServiceLogsQuery,
} = serviceWaypointsApi;
