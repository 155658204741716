import httpService from '../lib/http.service';
import {
  GetRouteGroupItemsQueryDto,
  GetRouteGroupItemWaypointsQueryDto,
  ObjectService,
  RouteGroupItem,
  RouteGroupItemWaypoint,
} from '@ekt-group/general-purpose-api-interfaces';
import { RouteGroupItemWaypointsSideloading } from '@ekt-group/general-purpose-api-interfaces/src/types/route-group-item-waypoints.enum';
import { RouteGroupItemSideloading } from '@ekt-group/general-purpose-api-interfaces/src/types/route-group-items.enum';
import { getTodayDate, getTomorrowDate } from '@/helpers/dateHelper';
import { UserProfession } from '@/features/auth';

export interface GetWaypointsDto {
  routeGroupItemIds?: number[];
  waypointIds?: number[];
}

export interface GetRouteGroupItemsListDto {
  userId: number;
  role: UserProfession;
}

const HOUR_TO_ADD_TOMORROW_ROUTES = 15;

const shouldAddTomorrowRoutes = () => {
  const currentHour = new Date().toLocaleString('et', {
    hour12: false,
    timeZone: 'Europe/Tallinn',
    hour: 'numeric',
  });

  return Number(currentHour) >= HOUR_TO_ADD_TOMORROW_ROUTES;
};

export default {
  async getRouteGroupItemsList({ userId, role }: GetRouteGroupItemsListDto) {
    const requestParams = [];

    const commonParams: GetRouteGroupItemsQueryDto = {
      sideloading: [
        RouteGroupItemSideloading.RouteGroup,
        RouteGroupItemSideloading.Car,
        RouteGroupItemSideloading.Driver,
        RouteGroupItemSideloading.Loader,
        RouteGroupItemSideloading.Base,
      ],
      startDate: getTodayDate(),
      endDate: shouldAddTomorrowRoutes() ? getTomorrowDate() : getTodayDate(),
      disablePagination: true,
    };

    if (!role) {
      requestParams.push({ ...commonParams, driverIds: userId });
    }
    if ([UserProfession.Driver, UserProfession.DriverLoader].includes(role)) {
      requestParams.push({ ...commonParams, driverIds: userId });
      requestParams.push({ ...commonParams, loaderIds: userId });
    }
    if (role === UserProfession.Loader) {
      requestParams.push({ ...commonParams, loaderIds: userId });
    }

    const responses = await Promise.all(
      requestParams.map((params) => {
        return httpService.get<RouteGroupItem[]>('/route-group-items', { params });
      }),
    );

    if (!responses?.length) {
      return [];
    }

    return responses.flat().reduce((routeGroupItems, { data }) => {
      return [...routeGroupItems, ...data];
    }, []);
  },
  async getWaypoints({ routeGroupItemIds, waypointIds }: GetWaypointsDto) {
    const params: GetRouteGroupItemWaypointsQueryDto = {
      sideloading: [
        RouteGroupItemWaypointsSideloading.Owner,
        RouteGroupItemWaypointsSideloading.ObjectItem,
        RouteGroupItemWaypointsSideloading.Object,
        RouteGroupItemWaypointsSideloading.ObjectItemDischarge,
        RouteGroupItemWaypointsSideloading.Contract,
        RouteGroupItemWaypointsSideloading.Kojv,
        RouteGroupItemWaypointsSideloading.KojvService,
        RouteGroupItemWaypointsSideloading.WaypointLogDetails,
      ],
      disablePagination: true,
    };

    if (routeGroupItemIds && routeGroupItemIds.length > 0) {
      params.routeGroupItemIds = routeGroupItemIds;
    }
    if (waypointIds && waypointIds.length > 0) {
      params.ids = waypointIds;
    }

    return httpService.get<RouteGroupItemWaypoint[]>('/route-group-item-waypoints', {
      params,
    });
  },
  async getServiceWaypoints({ routeGroupItemIds, waypointIds }: GetWaypointsDto) {
    const params: any = {
      sideloading: 'object,objectItem,contract,owner,relatedServices',
      isServiceDisabled: false,
      disablePagination: true,
    };

    if (routeGroupItemIds) {
      params['routeGroupItemIds'] = routeGroupItemIds.join();
    }
    if (waypointIds && waypointIds.length > 0) {
      params['ids'] = waypointIds.join();
    }

    return httpService.get<ObjectService[]>('/objects/services', {
      params,
    });
  },
};
