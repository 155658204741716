import React, { PropsWithChildren, forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CheckUpPicturesForm } from './CheckUpPicturesForm';
import { CheckUpCarProblemForm } from './CheckUpCarProblemForm';
import { AllDeviceTask } from '@ekt-group/bi-services-api-interfaces';
import { CarCheckOption, RouteGroupItem } from '@ekt-group/general-purpose-api-interfaces';
import TruckIcon from '@/components/icons/TruckIcon';
import { ValueOf } from '@/utils/value-of';
import { useMultistepForm } from '@/hooks/useMultiStepForm';
import { BasicFullScreenModal } from '@/components/modals';
import Button from '@/components/elements/Button';
import { CheckUpCarProblems } from './CheckUpCarProblems';
import { useSelector } from 'react-redux';
import { selectUserId, selectUserState } from '@/features/auth';
import { fileToBase64 } from '@/utils/files';
import { getTodayDate } from '@/helpers/dateHelper';
import { Toggle } from '@/components/elements/headlessui/Toggle';
import { useAllDeviceTasks } from '../../hooks';
import { useAppSelector } from '@/store/types';
import { ComboBoxOption } from '@/types/common';
import { AllDeviceServiceTask } from '@ekt-group/general-purpose-api-interfaces/src/types/alldevice.enum';
import { useTranslation } from 'react-i18next';
import Spinner from '@/components/elements/Spinner';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { cn } from '@/utils/cn';
import useGeolocation from 'react-hook-geolocation';

export type CheckUpModalMode = 'full' | 'addProblemOnly';

interface CheckUpModalProps {
  isVisible: boolean;
  onAllStepsCompleted: (data: CheckUpFormData) => void;
  onCancel?: () => void;
  mode: CheckUpModalMode;
  allDeviceTasks: AllDeviceTask[];
  filteredPriorityOptions: ComboBoxOption[];
  carCheckOptions: CarCheckOption[];
  routeGroupItem: RouteGroupItem;
}

/* 
Have two modes: full and addProblemOnly
In full mode, the user goes through all the steps: pictures, registered car issues, car problem
In addProblemOnly mode, the user goes through only the car problem step
(This is why the index of the steps is different and can not be hardcoded, do NOT use STEP_INDEX to do comparision)
*/

export const FORM_TYPE = {
  PICTURES: 'picturesForm',
  REGISTERED_CAR_ISSUES: 'registeredCarIssuesForm',
  CAR_PROBLEM: 'carProblemForm',
} as const;

export type CheckUpFormData = {
  driverId: number;
  carId: string;
  [FORM_TYPE.PICTURES]: {
    files?: {
      file: File;
      position: number;
    }[];
  };
  [FORM_TYPE.CAR_PROBLEM]: {
    serviceName?: string;
    description?: string;
    photos?: File[];
    priority?: number;
  };
};

export interface ExposedCheckUpModalFunctions {
  resetForm: () => void;
}

export const STEP_INDEX = {
  PICTURES: 0,
  REGISTERED_CAR_ISSUES: 1,
  CAR_PROBLEM: 2,
} as const;

const CheckUpModal = forwardRef<ExposedCheckUpModalFunctions, PropsWithChildren<CheckUpModalProps>>(
  (
    {
      isVisible,
      children,
      onAllStepsCompleted,
      onCancel,
      mode = 'full',
      allDeviceTasks = [],
      filteredPriorityOptions,
      carCheckOptions = [],
      routeGroupItem,
    },
    ref,
  ) => {
    const user = useAppSelector(selectUserState);
    const { longitude, latitude } = useGeolocation();

    const { createTask, allDevicePriorityOptions } = useAllDeviceTasks({
      deviceName: routeGroupItem?.car?.carNumber || '',
    });

    const [isAllTasksShown, setIsAllTaskShown] = useState<boolean>(false);
    const { t } = useTranslation('carCheckUpModal');
    const { isAboveMd } = useBreakpoint('md');
    const filteredTasks = useMemo(() => {
      if (!Array.isArray(allDeviceTasks)) {
        return [];
      }
      return isAllTasksShown ? allDeviceTasks : allDeviceTasks.filter((task) => !task.isCompleted);
    }, [allDeviceTasks, isAllTasksShown]);

    const defaultValues: CheckUpFormData = {
      driverId: user?.id || 0,
      carId: routeGroupItem?.car?.carNumber || '',
      [FORM_TYPE.PICTURES]: {
        files: [],
      },

      // TODO: separate this form into its own form, this is not related to the pictures form
      [FORM_TYPE.CAR_PROBLEM]: {
        serviceName: carCheckOptions?.[0]?.name || '',
        description: '',
        photos: [],
        priority: 0,
      },
    };

    const { handleSubmit, setValue, watch, reset } = useForm<CheckUpFormData>({
      defaultValues,
    });

    const currentValues = watch();

    const isAllFilesUploaded = currentValues.picturesForm.files.length === 4;

    const handleUpdateFields = (fields: Partial<CheckUpFormData>, source: ValueOf<typeof FORM_TYPE>) => {
      switch (source) {
        case FORM_TYPE.PICTURES:
          const uniqueFiles = fields.picturesForm.files.filter(
            (file, index, self) => index === self.findIndex((t) => t.position === file.position),
          );
          setValue('picturesForm', { ...currentValues.picturesForm, files: uniqueFiles });
          break;
        case FORM_TYPE.CAR_PROBLEM:
          setValue('carProblemForm', { ...currentValues.carProblemForm, ...fields.carProblemForm });
          break;
      }
    };

    const onAddProblem = async (problemInputData: CheckUpFormData['carProblemForm']) => {
      const formattedBase64Images = await Promise.all(
        problemInputData.photos.map(async (file) => {
          const base64 = await fileToBase64(file);
          return {
            name: file.name,
            content: base64.toString().split(',')[1],
          };
        }),
      );

      const description = `
          ${problemInputData.description}
          ${user.firstname} ${user.surname}, ${user.phone}
        `;

      createTask({
        deviceName: routeGroupItem?.car?.carNumber,
        pictures: formattedBase64Images || [],
        userId: user?.id.toString(),
        priority: problemInputData.priority,
        serviceName: problemInputData.serviceName,
        serviceDescription: description,
        serviceDate: getTodayDate(),
        serviceType: AllDeviceServiceTask.EXTRAORDINARY,
        latitude,
        longitude,
        routeGroupItemId: routeGroupItem?.id,
      });
    };

    const STEP_DESIGN_CONFIG = [
      {
        id: STEP_INDEX.PICTURES,
        component: <CheckUpPicturesForm values={currentValues.picturesForm} updateFields={handleUpdateFields} />,
        title: t('picturesForm.title'),
        submitButton: t('picturesForm.submitBtn'),
        cancelButton: t('picturesForm.cancelBtn'),
        enabled: mode === 'full',
      },
      {
        id: STEP_INDEX.REGISTERED_CAR_ISSUES,
        component: (
          <CheckUpCarProblems key={FORM_TYPE.REGISTERED_CAR_ISSUES} tasksData={filteredTasks} priorityOptions={allDevicePriorityOptions} />
        ),
        title: t('table.title'),
        submitButton: t('table.submitBtn'),
        cancelButton: t('table.cancelBtn'),
        enabled: true,
      },
      {
        id: STEP_INDEX.CAR_PROBLEM,
        component: (
          <CheckUpCarProblemForm
            key={FORM_TYPE.CAR_PROBLEM}
            carCheckOptions={carCheckOptions}
            priorityOptions={filteredPriorityOptions}
            updateFields={handleUpdateFields}
            currentValues={currentValues.carProblemForm}
          />
        ),
        title: t('problemForm.title'),
        submitButton: t('problemForm.submitBtn'),
        cancelButton: t('problemForm.cancelBtn'),
        enabled: true,
      },
    ];

    const enabledStepsConfig = STEP_DESIGN_CONFIG.filter((step) => step.enabled);
    const allSteps = enabledStepsConfig.map((step) => step.component);
    const carProblemsStepId = enabledStepsConfig.findIndex((step) => step.id === STEP_INDEX.REGISTERED_CAR_ISSUES);
    const addProblemStepId = enabledStepsConfig.findIndex((step) => step.id === STEP_INDEX.CAR_PROBLEM);

    const STEP_BEHAVIOR_CONFIG = [
      {
        id: STEP_INDEX.PICTURES,
        onSubmitButtonClicked: () => {
          goTo(carProblemsStepId);
        },
      },
      {
        id: STEP_INDEX.REGISTERED_CAR_ISSUES,
        onSubmitButtonClicked: () => {
          onAllStepsCompleted(currentValues);
        },
      },
      {
        id: STEP_INDEX.CAR_PROBLEM,
        onSubmitButtonClicked: () => {
          onAddProblem(currentValues.carProblemForm);
          goTo(carProblemsStepId);
        },
      },
    ];

    const { currentStepIndex, currentStepComponent, isFirstStep, isLastStep, back, next, goTo } = useMultistepForm(allSteps);

    useImperativeHandle(ref, () => ({
      resetForm: () => {
        reset(defaultValues);
        goTo(0);
      },
    }));

    const handleNextStep = () => {
      const currentStepId = enabledStepsConfig[currentStepIndex].id;
      return STEP_BEHAVIOR_CONFIG[currentStepId].onSubmitButtonClicked();
    };

    const handleBackStep = () => {
      if (isFirstStep) {
        reset(defaultValues);
        onCancel?.();
      }
      back();
    };

    const getTitleText = () => {
      return enabledStepsConfig[currentStepIndex].title;
    };

    const getNextButtonText = () => {
      return enabledStepsConfig[currentStepIndex].submitButton;
    };

    const getBackButtonText = () => {
      return enabledStepsConfig[currentStepIndex].cancelButton;
    };

    const isSubmitButtonDisabled = import.meta.env.VITE_IS_TESTING_ENVIRONMENT ? false : !isAllFilesUploaded;
    const isAddProblemButtonVisible = currentStepIndex === carProblemsStepId;
    const isAddProblemButtonDisabled = !navigator.onLine;
    const isShowHistorySwitchVisible = currentStepIndex === carProblemsStepId;

    const car = routeGroupItem?.car;

    const handleAddProblemButtonClicked = async () => {
      setValue('carProblemForm', defaultValues[FORM_TYPE.CAR_PROBLEM]);
      goTo(addProblemStepId);
    };

    const Header = () => {
      return (
        <div className="flex items-center justify-between w-full gap-x-2">
          <div className="max-w-5xl text-xl font-bold lg:text-3xl">{getTitleText()}</div>
          <div
            className={cn('flex flex-col-reverse items-end md:flex-row gap-x-2 md:gap-x-5 md:items-center md:justify-between', {
              'flex-1': isShowHistorySwitchVisible,
            })}
          >
            {isShowHistorySwitchVisible && (
              <div className="flex items-center gap-x-1 md:gap-x-2">
                <span className="text-md md:text-xl">{t('table.showHistoryLabel')}</span>
                <Toggle
                  checked={isAllTasksShown}
                  onChange={(checked) => {
                    setIsAllTaskShown(() => checked);
                  }}
                  size={isAboveMd ? 'md' : 'sm'}
                />
              </div>
            )}
            <div className="flex items-center text-2xl font-bold gap-x-2 md:text-3xl">
              <TruckIcon size="md" className="icon-lg md:icon-lg" />
              <span className="">{car?.carNumber}</span>
            </div>
          </div>
        </div>
      );
    };

    const Footer = () => {
      if (mode === 'full') {
        // Mobile
        if (!isAboveMd) {
          return (
            <div className="flex flex-col h-full px-2 py-1 gap-y-2 md:px-4 md:py-2">
              {isAddProblemButtonVisible && (
                <Button
                  size={'md'}
                  className="flex-1 w-full font-bold"
                  color="primary"
                  text={t('problemForm.title')}
                  onClick={handleAddProblemButtonClicked}
                  disabled={isAddProblemButtonDisabled}
                />
              )}
              <div className="flex items-center h-full gap-x-2">
                <Button size={'md'} className="flex-1 font-bold" color="danger" text={getBackButtonText()} onClick={handleBackStep} />
                <Button
                  size={'md'}
                  className="flex-1 px-1 font-bold"
                  color="success"
                  text={getNextButtonText()}
                  onClick={handleNextStep}
                  disabled={isSubmitButtonDisabled}
                />
              </div>
            </div>
          );
        }

        // Desktop
        return (
          <div className="flex justify-end px-2 py-2 gap-x-5">
            <Button size={'md'} className="font-bold" color="danger" text={getBackButtonText()} onClick={handleBackStep} />
            {isAddProblemButtonVisible && (
              <>
                <Button
                  size={'md'}
                  className="flex-grow-[2] font-bold"
                  color="primary"
                  text={t('problemForm.title')}
                  onClick={handleAddProblemButtonClicked}
                  disabled={isAddProblemButtonDisabled}
                />
              </>
            )}
            <Button
              size={'md'}
              className="px-1 font-bold"
              color="success"
              text={getNextButtonText()}
              onClick={handleNextStep}
              disabled={isSubmitButtonDisabled}
            />
          </div>
        );
      }

      if (mode === 'addProblemOnly') {
        return (
          <div className="flex justify-between h-full px-2 py-2 gap-x-5 md:gap-x-20 md:px-4">
            <Button size={'md'} className="flex-1 font-bold" color="danger" text={getBackButtonText()} onClick={handleBackStep} />
            {isAddProblemButtonVisible && (
              <>
                <Button
                  size={'md'}
                  className="flex-1 font-bold"
                  color="primary"
                  text={t('problemForm.title')}
                  onClick={handleAddProblemButtonClicked}
                />
              </>
            )}
            {!isAddProblemButtonVisible && (
              <Button
                size={'md'}
                className="flex-1 px-1 font-bold"
                color="success"
                text={getNextButtonText()}
                onClick={handleNextStep}
                disabled={isSubmitButtonDisabled}
              />
            )}
          </div>
        );
      }
    };

    // we need routeGroupItem to be able to get the car number and to have a deviceName to send tasks to
    if (!routeGroupItem) {
      return null;
    }

    return (
      <BasicFullScreenModal
        mainClassNames="md:p-2"
        isCloseable={false}
        onClose={onCancel}
        renderHeader={Header}
        renderFooter={Footer}
        isVisible={isVisible}
      >
        {/* div is here to control the height or width of the main element */}
        <div className="flex w-full h-[calc(100vh-10.5rem)] overflow-auto">{currentStepComponent}</div>
      </BasicFullScreenModal>
    );
  },
);

CheckUpModal.displayName = 'CheckUpModal';
export default CheckUpModal;
