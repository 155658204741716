import { useState, useEffect } from 'react';
import { photoDB, PhotoDb } from '@/database';
import { useLazyGetServiceWaypointPicturesQuery, useLazyGetWaypointPicturesQuery } from '../../api';
import { WaypointType } from '@/features/home';
import { useLiveQuery } from 'dexie-react-hooks';

// same type as ArrivedPageSliceState.photos[waypointId]
interface PhotoLog {
  logId: number;
  filePath: string;
}

interface MergedPhoto {
  photoId: number;
  filePath: string;
  url: string;
}

const filterPhotosByEntityIds = (photos: PhotoDb[], entityIds: number[]) =>
  photos?.filter(({ waypointIds }) => waypointIds?.some((id) => entityIds.includes(id))) || [];

const mapLocalPhotos = (photos: PhotoDb[] | undefined): MergedPhoto[] => {
  if (!photos?.length) {
    return [];
  }

  return photos.map(({ id, serverFilePath, photo }) => ({
    photoId: id,
    filePath: serverFilePath,
    url: URL.createObjectURL(photo),
  }));
};

export const useArrivedPageMergePhotos = (photoLogs: PhotoLog[] | undefined, entityIds: number[], type: WaypointType) => {
  const [mergedPhotos, setMergedPhotos] = useState<MergedPhoto[]>([]);

  const [getServiceWaypointPictures, { isFetching: isServiceWaypointPicturesFetching }] = useLazyGetServiceWaypointPicturesQuery();
  const [getDischargeWaypointPictures, { isFetching: isDischargeWaypointPicturesFetching }] = useLazyGetWaypointPicturesQuery();

  const localPhotos = useLiveQuery(() => photoDB.photos.toArray());

  useEffect(() => {
    const mergePhotos = async () => {
      const filteredLocalPhotos = filterPhotosByEntityIds(localPhotos ?? [], entityIds);

      // If there are no photo logs or the number of local photos is the same as the number of photo logs, return local photos
      if (!photoLogs?.length || filteredLocalPhotos.length === photoLogs.length) {
        setMergedPhotos(mapLocalPhotos(filteredLocalPhotos));
        return;
      }

      try {
        const [serverPhotos, localPhotos] = await Promise.all([
          type === WaypointType.Service
            ? getServiceWaypointPictures({ serviceIds: entityIds }).unwrap()
            : getDischargeWaypointPictures({ waypointIds: entityIds }).unwrap(),
          photoDB.photos.toArray(),
        ]);

        if (!serverPhotos?.length) {
          setMergedPhotos(mapLocalPhotos(filteredLocalPhotos));
          return;
        }

        const serverPhotoMap = new Map(serverPhotos.map((photo) => [photo.id, photo]));

        // Get photos from server that are in photoLogs
        const serverPhotosFromLogs: MergedPhoto[] = photoLogs
          .filter(({ logId }) => serverPhotoMap.has(logId))
          .map(({ logId, filePath }) => ({
            photoId: logId,
            filePath,
            url: serverPhotoMap.get(logId)?.photo || '',
          }));

        // Get photos from server that aren't in photoLogs
        const additionalServerPhotos: MergedPhoto[] = serverPhotos
          .filter(({ id }) => !photoLogs.some((log) => log.logId === id))
          .map((photo) => ({
            photoId: photo.id,
            filePath: '',
            url: photo.photo,
          }));

        const merged = [
          ...serverPhotosFromLogs,
          ...additionalServerPhotos,
          ...mapLocalPhotos(
            filterPhotosByEntityIds(
              localPhotos?.filter(({ serverFilePath }) => !serverFilePath),
              entityIds,
            ),
          ),
        ];

        setMergedPhotos(merged);
      } catch (error) {
        console.error('Failed to fetch server photos:', error);
        setMergedPhotos(mapLocalPhotos(filteredLocalPhotos));
      }
    };

    mergePhotos();
  }, [entityIds, getServiceWaypointPictures, getDischargeWaypointPictures, localPhotos, photoLogs, type]);

  return { mergedPhotos, isFetching: isServiceWaypointPicturesFetching || isDischargeWaypointPicturesFetching };
};
