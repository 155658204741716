import { CarCheckOption } from '@ekt-group/general-purpose-api-interfaces';
import { baseApi, CAR_CHECKS_OPTIONS_KEY } from '@/store/baseApi';

export interface GetCarCheckDto {
  type?: string;
  isDeleted?: boolean;
  isEhitus?: boolean;
  isOlme?: boolean;
}

const enhancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [CAR_CHECKS_OPTIONS_KEY],
});

export const carsChecksApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getCarsCheckOptions: builder.query<CarCheckOption[], GetCarCheckDto>({
      query: (params) => {
        return {
          url: '/cars/check-options',
          method: 'GET',
          params,
        };
      },
      providesTags: [CAR_CHECKS_OPTIONS_KEY],
    }),
  }),
});

export const { useGetCarsCheckOptionsQuery } = carsChecksApi;
