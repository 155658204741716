import React from 'react';
import Button from '@/components/elements/Button';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import useModal from '@/hooks/useModal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getTodayDate, isToday } from '@/helpers/dateHelper';
import { setPhotoUploadingStatus } from '@/helpers/localStorage/photoUploadingHelper';
import { PhotoUploadingStatus, usePhotoUploadForm } from '@/features/photo';
import { photoDB, PhotoEntity } from '@/database';
import { AppMode } from '@/types/common';
import { useRollbar } from '@rollbar/react';
import { useSelector } from 'react-redux';
import { selectCurrentRouteGroupItem } from '@/features/home';
import { selectMode } from '@/store/settings/slice';
import { selectUserId } from '@/features/auth';
import PhotoInputWithCoordinates from './PhotoInputWithCoordinates';
import { ObjectEntity } from '@ekt-group/general-purpose-api-interfaces';
import { useAppDispatch } from '@/store/types';
import { setIsPhotoPresent } from '@/features/route';

interface RoutePhotoUploadFormProps {
  disableSubmit: boolean;
  waypointIds: number[];
  arrivedObject?: ObjectEntity;
}

export const RoutePhotoUploadForm = ({ waypointIds, arrivedObject, disableSubmit }: RoutePhotoUploadFormProps) => {
  const { isVisible, toggle: toggleModal } = useModal();
  const { t } = useTranslation();
  const rollbar = useRollbar();

  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const driverId = useSelector(selectUserId);
  const appMode = useSelector(selectMode);
  const dispatch = useAppDispatch();

  const { inputRef, photo, setPhoto, photoPreview, resetInput, uploadPhoto } = usePhotoUploadForm({
    onUpload: async () => {
      if (!waypointIds?.length) {
        toast(t('selectWaypoint', { ns: 'photoPage' }), {
          type: 'error',
        });
        return;
      }

      dispatch(setIsPhotoPresent(true));

      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadRequestAddingToDatabase);

      await photoDB.photos.add({
        photo,
        waypointIds,
        routeGroupItemName: currentRouteGroupItem?.name,
        routeGroupName: currentRouteGroupItem?.routeGroup?.name,
        routeGroupItemId: currentRouteGroupItem?.id,
        timestamp: Date.now(),
        isBasicAppMode: appMode === AppMode.Basic,
        entity: currentRouteGroupItem?.isDischargeSheet ? PhotoEntity.DischargeWaypoint : PhotoEntity.ServiceWaypoint,
      });

      dispatch(setIsPhotoPresent(false));
      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadRequestAddedToDatabase);
    },
  });

  const handlePhotoDeleted = () => {
    toggleModal();
    dispatch(setIsPhotoPresent(false));
  };

  const handlePhotoAdded = () => {
    dispatch(setIsPhotoPresent(true));
    if (disableSubmit) {
      rollbar.warn('Photo added but submit button is disabled', {
        waypointIds,
        arrivedObject,
        currentRouteGroupItem,
        todayDate: getTodayDate(),
      });
    }
  };

  return (
    <>
      <PhotoInputWithCoordinates
        arrivedObject={arrivedObject}
        photo={photo}
        photoPreview={photoPreview}
        setPhoto={setPhoto}
        resetInput={resetInput}
        inputRef={inputRef}
        doAfterCompression={handlePhotoAdded}
      />

      <div className="photo__submit-actions">
        <Button disabled={!Boolean(photo)} text={t('delete')} color="danger" size="md" onClick={handlePhotoDeleted} wide={true} />
        <Button
          disabled={!Boolean(photo) || disableSubmit}
          text={t('submit')}
          color="success"
          size="md"
          onClick={uploadPhoto}
          wide={true}
        />
      </div>

      <ConfirmationModal
        text={t('confirmationText', { ns: 'photoPage' })}
        submitText={t('delete')}
        isVisible={isVisible}
        hide={toggleModal}
        submit={resetInput}
      />
    </>
  );
};
