import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  CurrentRouteGroupItemMode,
  routeGroupItemsApi,
  selectCurrentRouteGroupItem,
  selectIsCurrentRouteGroupItemOpenedMode,
  setCurrentRouteGroupItem,
  setCurrentRouteGroupItemMode,
  setCurrentRouteSheet,
  useGetRouteGroupItemsListQuery,
} from '@/features/home';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectUserState } from '@/features/auth';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getTodayDate } from '@/helpers/dateHelper';
import { useIsRoutePage } from '@/helpers/router';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import { GetRouteGroupItemsListDto } from '@/services/general.service';
import { useAppDispatch, useAppSelector } from '@/store/types';
import generalService from '@/services/general.service';
import {
  selectSelectedWaypointListSortingOption,
  setCarLogCoordinates,
  setPlanningObject,
  setSelectedPlanningItem,
  setSelectedWaypointListSortingOption,
  useLazyGetWaypointsQuery,
  WaypointListSortingOption,
  waypointsApi,
} from '@/features/route';
import { resetWaypointsState } from '@/store/waypoints/slice';
import { resetFuelingPageState } from '@/features/fueling';
import { resetUnloadingPageState } from '@/features/unloading';
import { RouteGroupItem } from '@ekt-group/general-purpose-api-interfaces';
import { selectMode } from '@/store/settings/slice';
import { AppMode } from '@/types/common';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { RouteStartFinishRequest, routeStartFinishRequestsDb, RouteStartFinishRequestType } from '@/database';
import { useLiveQuery } from 'dexie-react-hooks';
import { updateRouteGroupItemsCache } from '../api/route-group-items.api';

export function useRouteGroupItemsRequest() {
  const [isRouteAndWaypointsUpdateLoading, setIsRouteAndWaypointsUpdateLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isOffline = useOfflineStatus();

  const userState = useAppSelector(selectUserState);
  const currentRouteGroupItem = useAppSelector(selectCurrentRouteGroupItem);

  const currentSortingOption = useAppSelector(selectSelectedWaypointListSortingOption);
  const appMode = useAppSelector(selectMode);
  const isBasicAppMode = appMode === AppMode.Basic;

  const {
    isLoading,
    isFetching,
    data: routeGroupItems,
    refetch,
    isUninitialized: isRouteGroupItemsCacheUninitialized,
  } = useGetRouteGroupItemsListQuery(
    {
      userId: userState?.id,
      role: userState?.profession?.id,
    },
    {
      skip: !userState?.id || !userState?.profession?.id,
    },
  );

  const pendingRouteGroupItemsRequests = useLiveQuery(() => routeStartFinishRequestsDb.requests.toArray());

  const mergeRouteGroupItems = useCallback(
    (pendingRouteGroupItemsRequests: RouteStartFinishRequest[], routeGroupItems: RouteGroupItem[]) => {
      if (!pendingRouteGroupItemsRequests?.length || !routeGroupItems?.length) {
        return routeGroupItems;
      }

      // Basically, we try to process the "finish" requests first, then the "start" requests
      // This is because there will be two requests for the same route id, one for start and one for finish
      // Route finish = Route has started, no need to process it
      const finishRequests = pendingRouteGroupItemsRequests.filter((request) => request.type === RouteStartFinishRequestType.Finish);
      const finishedRouteIds = new Set(finishRequests.map((request) => request.routeGroupItemId));

      const startRequests = pendingRouteGroupItemsRequests.filter(
        (request) => request.type === RouteStartFinishRequestType.Start && !finishedRouteIds.has(request.routeGroupItemId),
      );

      const pendingRequestsMap = new Map([...finishRequests, ...startRequests].map((request) => [request.routeGroupItemId, request]));

      return routeGroupItems.map((item) => {
        if (item.end) {
          return item;
        }

        const pendingRequest = pendingRequestsMap.get(item.id);
        if (pendingRequest) {
          return {
            ...item,
            start: pendingRequest.startTime,
            end: pendingRequest.endTime,
            isDone: pendingRequest.type === RouteStartFinishRequestType.Finish,
          };
        }
        return item;
      });
    },
    [],
  );

  useEffect(() => {
    if (!pendingRouteGroupItemsRequests?.length || !routeGroupItems?.length) {
      return;
    }

    const originalArgs: GetRouteGroupItemsListDto = {
      userId: userState?.id,
      role: userState?.profession?.id,
    };

    const mergedRouteGroupItems = mergeRouteGroupItems(pendingRouteGroupItemsRequests, routeGroupItems);

    dispatch(
      routeGroupItemsApi.util.updateQueryData('getRouteGroupItemsList', originalArgs, (draft) => {
        updateRouteGroupItemsCache(mergedRouteGroupItems, draft);
      }),
    );
  }, [dispatch, mergeRouteGroupItems, pendingRouteGroupItemsRequests, routeGroupItems, userState?.id, userState?.profession?.id]);

  const [getWaypoints] = useLazyGetWaypointsQuery();

  const getRouteGroupItemById = useCallback(
    (id: number) => {
      return routeGroupItems?.find((item) => item.id === id);
    },
    [routeGroupItems],
  );

  const updateOdometerRTKCache = async (data: { carId: number; odometer: number }) => {
    const { odometer, carId } = data;
    const originalArgs: GetRouteGroupItemsListDto = {
      userId: userState?.id,
      role: userState?.profession?.id,
    };

    dispatch(
      routeGroupItemsApi.util.updateQueryData('getRouteGroupItemsList', originalArgs, (draft) => {
        const item = draft.find((item) => item.carId === carId);
        if (item) {
          item.car.odometer = odometer;
        }
      }),
    );
  };

  const refreshRoutesAndGetNewWaypoints = useCallback(async () => {
    if (isOffline) {
      return;
    }
    setIsRouteAndWaypointsUpdateLoading(true);

    try {
      const currentRouteIds = routeGroupItems.map((route) => route.id);
      if (!isRouteGroupItemsCacheUninitialized) {
        const { data: newRouteGroupItems } = await refetch();
        const newRoutes = newRouteGroupItems?.filter((route) => !currentRouteIds.includes(route.id)) || [];
        if (newRoutes?.length > 0) {
          for (const route of newRoutes) {
            // make simple http request for setting response into cache, which will be re-used in real request
            route.isDischargeSheet
              ? await generalService.getWaypoints({ routeGroupItemIds: [route.id] })
              : await generalService.getServiceWaypoints({ routeGroupItemIds: [route.id] });
          }
        }
      }

      // update waypoints list, if current route exists
      if (currentRouteGroupItem) {
        await getWaypoints({
          routeGroupItemIds: [currentRouteGroupItem.id],
          isDischargeSheet: currentRouteGroupItem.isDischargeSheet,
          waypointIds: [],
        });
      }
    } finally {
      setIsRouteAndWaypointsUpdateLoading(false);
    }
  }, [isOffline, routeGroupItems, isRouteGroupItemsCacheUninitialized, currentRouteGroupItem, refetch, getWaypoints]);

  const setRouteGroupItemAsCurrent = useCallback(
    (routeGroupItemId: number) => {
      const currentRouteGroupItem = routeGroupItems.find((item) => item.id === routeGroupItemId);
      dispatch(setCurrentRouteGroupItem(currentRouteGroupItem));
      dispatch(setCurrentRouteSheet(null));

      dispatch(resetWaypointsState());
      dispatch(resetFuelingPageState());
      dispatch(resetUnloadingPageState());
      dispatch(setSelectedPlanningItem(null));
      dispatch(setPlanningObject(null));
      dispatch(setCarLogCoordinates(null));

      if (currentSortingOption === WaypointListSortingOption.Optimized) {
        dispatch(setSelectedWaypointListSortingOption(WaypointListSortingOption.Alphabetical));
      }

      localStorage.setItem('currentRouteGroupItemId', routeGroupItemId.toString());
      localStorage.removeItem('selectedWaypointIds');
    },
    [dispatch, routeGroupItems, currentSortingOption],
  );

  const changeRouteGroupItemMode = useCallback(
    (routeGroupItem: RouteGroupItem) => {
      const isTodayRoute = routeGroupItem?.date === getTodayDate();

      const isActive = isBasicAppMode
        ? currentRouteGroupItem?.id === routeGroupItem?.id
        : routeGroupItem?.start && !routeGroupItem?.end && !routeGroupItem?.isDone && isTodayRoute;

      if (!isBasicAppMode) {
        const routeGroupItemMode = isActive ? CurrentRouteGroupItemMode.Process : CurrentRouteGroupItemMode.Open;
        dispatch(setCurrentRouteGroupItemMode(routeGroupItemMode));
        localStorage.setItem('currentRouteGroupItemMode', routeGroupItemMode);
      }
    },
    [currentRouteGroupItem?.id, dispatch, isBasicAppMode],
  );

  const reselectWaypointCache = useCallback(async () => {
    const store = await import('@/store').then((module) => module.store);

    const { data } = waypointsApi.endpoints.getWaypoints.select({
      routeGroupItemIds: [currentRouteGroupItem.id],
      isDischargeSheet: currentRouteGroupItem.isDischargeSheet,
      waypointIds: [],
    })(store.getState());

    return data;
  }, [currentRouteGroupItem?.id, currentRouteGroupItem?.isDischargeSheet]);

  return {
    routeGroupItems,
    currentRouteGroupItem,
    isLoading,
    isFetching,
    refetch,
    updateOdometerRTKCache,
    isRouteAndWaypointsUpdateLoading,
    refreshRoutesAndGetNewWaypoints,
    setRouteGroupItemAsCurrent,
    changeRouteGroupItemMode,
    getRouteGroupItemById,
    reselectWaypointCache,
  };
}
