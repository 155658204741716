import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { onFulfilledResponse, onFailedResponse, cleanParams, getRetryConfig } from './http.interceptors';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { getBackendHost } from '@/helpers/backend';
import axiosRetry from 'axios-retry';

const axiosInstance = axios.create({
  baseURL: getBackendHost(),
  withCredentials: true,
});

axiosInstance.interceptors.response.use(onFulfilledResponse, onFailedResponse);
axiosInstance.interceptors.request.use(cleanParams);

axiosRetry(axiosInstance, getRetryConfig());
export interface AxiosArgs {
  url: string;
  method?: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
}

/**
 * Base query function for RTK Query integration
 * Note: This function only executes when called through RTK Query endpoints
 * Direct axios calls (e.g. httpService.get()) bypass this function
 *
 * @example
 * // Will execute this function:
 * const api = createApi({
 *   baseQuery: axiosBaseQuery,
 *   endpoints: (builder) => ({
 *     getData: builder.query({ ... })
 *   })
 * });
 *
 * // Will NOT execute this function:
 * await httpService.get('/data');
 */
export const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: '' },
): BaseQueryFn<AxiosArgs, unknown, { status: string; data: AxiosError['response']['data']; error: AxiosError }> => {
  return async ({ url, method, data, params, headers }) => {
    try {
      const result = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });

      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.status,
          data: JSON.parse(JSON.stringify(err.response?.data)) || err.message,
          error: JSON.parse(JSON.stringify(err)),
        },
      };
    }
  };
};

export const extendedBaseQuery = (args: AxiosArgs, api: any, extraOptions: any) =>
  axiosBaseQuery({ baseUrl: getBackendHost() })(args, api, extraOptions);

export default {
  get<RequestEntity>(url: string, config: AxiosRequestConfig = {}) {
    return axiosInstance.get<RequestEntity>(url, config);
  },
  post<RequestEntity>(url: string, body: Record<string, any> | FormData, config: AxiosRequestConfig = {}) {
    return axiosInstance.post<RequestEntity>(url, body, config);
  },
  put<RequestEntity>(url: string, body: Record<string, any>, config: AxiosRequestConfig = {}) {
    return axiosInstance.put<RequestEntity>(url, body, config);
  },
  patch<RequestEntity>(url: string, body: Record<string, any>, config: AxiosRequestConfig = {}) {
    return axiosInstance.patch<RequestEntity>(url, body, config);
  },
  delete<RequestEntity>(url: string, config: AxiosRequestConfig = {}) {
    return axiosInstance.delete<RequestEntity>(url, config);
  },
};
