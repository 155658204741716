import { configureStore, Store } from '@reduxjs/toolkit';
import { routeGroupItemsSlice } from '@/features/home';
import { authSlice } from '@/features/auth';
import { settingsSlice } from './settings/slice';
import { commonSlice } from './common/slice';
import { fuelingPageSlice } from '@/features/fueling';
import { unloadingPageSlice } from '@/features/unloading';
import { arrivedPageSlice, keysAndGates, mapObjectsSlice, tabletPageSettingsSlice } from '@/features/route';
import { waypointsSlice } from './waypoints/slice';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { baseApi } from './baseApi';
import { AxiosArgs } from '@/lib/http.service';

export const store = configureStore({
  reducer: {
    [settingsSlice.name]: settingsSlice.reducer,
    [authSlice.name]: authSlice.reducer,
    [routeGroupItemsSlice.name]: routeGroupItemsSlice.reducer,
    [tabletPageSettingsSlice.name]: tabletPageSettingsSlice.reducer,
    [keysAndGates.name]: keysAndGates.reducer,
    [commonSlice.name]: commonSlice.reducer,
    [fuelingPageSlice.name]: fuelingPageSlice.reducer,
    [unloadingPageSlice.name]: unloadingPageSlice.reducer,
    [arrivedPageSlice.name]: arrivedPageSlice.reducer,
    [waypointsSlice.name]: waypointsSlice.reducer,
    [mapObjectsSlice.name]: mapObjectsSlice.reducer,

    [baseApi.reducerPath]: baseApi.reducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([baseApi.middleware]),
});

export default store;

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type Endpoint = {
  endpointName: string;
  originalArgs: any;
  queryCacheKey: string;
};
