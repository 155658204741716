import React, { useEffect, useState } from 'react';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import Button from '../../../components/elements/Button';
import { useTranslation } from 'react-i18next';
import externalService from '../../external/api';
import Spinner from '../../../components/elements/Spinner';
import routeGroupItemsService from '../api/route-group-items.service';
import { RouteGroupItem } from '@ekt-group/general-purpose-api-interfaces';
import StartIcon from '../../../components/icons/StartIcon';
import ArrivedIcon from '../../../components/icons/ArrivedIcon';
import { RouteGroupItemSummary, RouteGroupItemType, useRouteGroupItemsRequest } from '@/features/home';
import VerticalShadowScroll from '../../../components/elements/VerticalShadowScroll';
import RouteGroupItemStartFinishModalSummaryTables from './RouteGroupItemStartFinishModalSummaryTables';
import { routeStartFinishRequestsDb, RouteStartFinishRequestType } from '@/database';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import Input from '../../../components/elements/Input';
import { toast } from 'react-toastify';
import { useFinishMutation, useStartMutation } from '@/features/home';
import { cn } from '@/utils/cn';
import { OdometerInput } from './OdometerInput';

interface RouteGroupItemStartFinishModalProps {
  isVisible: boolean;
  onClose: () => void;
  onStart: () => void;
  onFinish?: () => void;
  routeGroupItem: RouteGroupItem;
  action: 'start' | 'finish';
}

const InfoItem = ({ label, value, isLoading = false }: { label: string; value: string; isLoading?: boolean }) => {
  if (isLoading) {
    return (
      <div className="flex gap-x-2">
        <span>{label}:</span>
        <span>
          <Spinner size={'md'} />
        </span>
      </div>
    );
  }

  return (
    <div>
      {label}:
      <span className="ml-1 text-right">
        <b>{value}</b>
      </span>
    </div>
  );
};

const RouteGroupItemStartFinishModal = ({
  isVisible,
  routeGroupItem,
  action,
  onClose,
  onStart,
  onFinish,
}: RouteGroupItemStartFinishModalProps) => {
  const [inputValue, setInputValue] = useState('');
  const [startOdometerValue, setStartOdometerValue] = useState<number>(+routeGroupItem?.car?.odometer || 0);
  const [carState, setCarState] = useState(null);
  const [routeSummary, setRouteSummary] = useState<RouteGroupItemSummary>(null);
  const [isCarStateLoading, setIsCarStateLoading] = useState<boolean>(false);
  const [isStartFinishLoading, setIsStartFinishLoading] = useState<boolean>(false);

  const [carNumber, setCarNumber] = useState('');
  const [initialOdometerValue, setInitialOdometerValue] = useState(0);
  const [routeGroupName, setRouteGroupName] = useState('');
  const { updateOdometerRTKCache } = useRouteGroupItemsRequest();

  const [startRoute] = useStartMutation();
  const [finishRoute] = useFinishMutation();

  const { t } = useTranslation('routeGroupItems');
  const isOffline = useOfflineStatus();

  const isStart = action === 'start';
  const isWaitingRouteType = routeGroupItem?.sheetType === RouteGroupItemType.Waiting;

  const getOdometerValueFromBiServices = async () => {
    if (!carNumber) {
      return;
    }

    setIsCarStateLoading(true);
    try {
      const state = await externalService.getCarState(carNumber);
      setCarState(state);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (state.odometer && state.odometer > routeGroupItem?.car?.odometer) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setInputValue(state.odometer);
      }
    } finally {
      setIsCarStateLoading(false);
    }
  };

  const getSummary = async () => {
    const summary = await routeGroupItemsService.getSummary(routeGroupItem?.id);
    setRouteSummary(summary);
  };

  useEffect(() => {
    if (!routeGroupItem) {
      return;
    }

    const { car, routeGroup, isDischargeSheet, name } = routeGroupItem;

    if (isStart) {
      setInitialOdometerValue(car?.odometer || 0);
    } else {
      setInitialOdometerValue(0);
    }
    setCarNumber(car?.carNumber || '');
    setRouteGroupName(isDischargeSheet ? routeGroup.name : name);
  }, [routeGroupItem]);

  useEffect(() => {
    if (isVisible && !isStart && routeGroupItem && routeGroupItem.isDischargeSheet) {
      getSummary();
    }
  }, [routeGroupItem, action, isVisible]);

  useEffect(() => {
    if (isVisible) {
      setInputValue(initialOdometerValue > 0 ? initialOdometerValue.toString() : '');
      if (!isOffline && carNumber) {
        getOdometerValueFromBiServices();
      }
      return;
    }

    setCarState(null);
    setRouteSummary(null);
    setIsCarStateLoading(true);
    setInputValue(initialOdometerValue > 0 ? initialOdometerValue.toString() : '');
  }, [isVisible, initialOdometerValue, carNumber]);

  const odometerValue = Number(inputValue);

  const handleStartFinishRoute = async () => {
    setIsStartFinishLoading(true);
    try {
      const isOdometerValueLessThanStart = isStart && odometerValue < +routeGroupItem?.car?.odometer;
      const isOdometerValueLessThanFinish = !isStart && odometerValue <= +startOdometerValue;

      if (!isWaitingRouteType && (isOdometerValueLessThanStart || isOdometerValueLessThanFinish)) {
        toast(t('startFinishModal.odometerValueLessThanStart'), { type: 'warning' });
        return;
      }

      const currentTime = new Date().toISOString();

      updateOdometerRTKCache({ carId: routeGroupItem.carId, odometer: odometerValue });

      if (isStart) {
        await startRoute({
          id: routeGroupItem.id,
          startTime: currentTime,
          odometer: startOdometerValue,
        })
          .unwrap()
          .catch(async () => {
            const existingRequest = await routeStartFinishRequestsDb.requests
              .where('routeGroupItemId')
              .equals(routeGroupItem.id)
              .filter((request) => request.type === RouteStartFinishRequestType.Start)
              .first();

            if (existingRequest) {
              return;
            }

            await routeStartFinishRequestsDb.requests.add({
              routeGroupItemId: routeGroupItem.id,
              carId: routeGroupItem.carId,
              type: RouteStartFinishRequestType.Start,
              startOdometerValue: odometerValue,
              startTime: currentTime,
            });
          });

        onStart();
        return;
      }

      await finishRoute({
        id: routeGroupItem.id,
        endTime: currentTime,
        odometer: odometerValue,
        startOdometer: startOdometerValue,
      })
        .unwrap()
        .catch(async () => {
          const existingRequest = await routeStartFinishRequestsDb.requests
            .where('routeGroupItemId')
            .equals(routeGroupItem.id)
            .filter((request) => request.type === RouteStartFinishRequestType.Finish)
            .first();

          if (existingRequest) {
            return;
          }

          await routeStartFinishRequestsDb.requests.add({
            routeGroupItemId: routeGroupItem.id,
            carId: routeGroupItem.carId,
            type: RouteStartFinishRequestType.Finish,
            startOdometerValue: startOdometerValue,
            endOdometerValue: odometerValue,
            endTime: currentTime,
          });
        });

      onFinish();
    } finally {
      setIsStartFinishLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^\d]/g, '');
    setInputValue(value);
  };

  return (
    <ModalWrapper
      isVisible={isVisible}
      className={cn('h-fit md:max-w-[75dvw]', {
        'md:max-w-[60dvw]': isStart,
      })}
    >
      <div className="flex flex-col justify-between h-full text-gray-900 gap-y-2 dark:text-gray-100">
        <div className="flex flex-col md:items-center md:grid md:grid-cols-[auto_auto] -mt-4 md:-mt-2 md:text-2xl">
          {!isWaitingRouteType && (
            <OdometerInput
              inputValue={inputValue}
              handleInputChange={handleInputChange}
              odometerValue={odometerValue}
              shouldDisplayTodayDistance={!isStart}
              startOdometerValue={startOdometerValue}
              className="order-2"
            />
          )}
          <VerticalShadowScroll
            className="flex flex-col order-1 mt-3 md:order-2 max-h-dynamic-35 md:max-h-dynamic-70"
            outerClassName="w-full"
          >
            <InfoItem label={t('routeGroup', { ns: 'common' })} value={routeGroupName} />
            {!isWaitingRouteType && (
              <>
                <InfoItem label={t('carNumber', { ns: 'common' })} value={carNumber} />
                <InfoItem
                  label={t('ignitionState', { ns: 'common' })}
                  value={carState?.ignition ? 'ON' : 'OFF'}
                  isLoading={isCarStateLoading}
                />
                <InfoItem
                  label={t('startFinishModal.canOdometerValue')}
                  value={carState?.odometer ? carState.odometer : 'N/A'}
                  isLoading={isCarStateLoading}
                />
              </>
            )}
            {!isStart && !isWaitingRouteType && (
              <div className="my-2 leading-5 route-group-item-start-finish-modal__summary">
                <span>
                  {t('startFinishModal.startOdometerValue')}:
                  <Input
                    className="mt-2"
                    inputMode={'decimal'}
                    value={startOdometerValue}
                    onChange={(value) => {
                      setStartOdometerValue(Number(value));
                    }}
                  />
                </span>
              </div>
            )}
            {!isStart && routeGroupItem?.isDischargeSheet && routeSummary && (
              <RouteGroupItemStartFinishModalSummaryTables routeSummary={routeSummary} />
            )}
          </VerticalShadowScroll>
        </div>
        <div className="flex gap-4">
          <Button text={t('close')} onClick={onClose} wide color={'disabled'} size={'lg'} />
          <Button
            text={isStart ? t('start', { ns: 'common' }) : t('finish', { ns: 'common' })}
            onClick={handleStartFinishRoute}
            wide
            pending={isStartFinishLoading}
            color={isStart ? 'success' : 'primary'}
            icon={isStart ? <StartIcon size={'md'} /> : <ArrivedIcon size={'sm'} />}
            size={'lg'}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default RouteGroupItemStartFinishModal;
