import React, { useEffect } from 'react';
import useUserAuth from '../features/auth/hooks/useUserAuth';
import Footer from '../components/navigation/Footer';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import { setDocumentTitle } from '../hooks/useDocumentTitle';
import { useSelector } from 'react-redux';
import { selectUserState } from '../features/auth/stores/slice';
import { ToastContainer } from 'react-toastify';
import SplashLoading from '../components/elements/SplashLoading';
import { useTheme } from '../contexts/themeContext';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';

const BaseLayout = () => {
  setDocumentTitle('Tablet app');

  const { theme } = useTheme();
  const isOffline = useOfflineStatus();
  const userState = useSelector(selectUserState);
  const { i18n } = useTranslation();
  const { isUserLoading } = useUserAuth();

  useEffect(() => {
    const locale = localStorage.getItem('locale');
    if (locale) {
      i18n.changeLanguage(locale);
    }

    if (CSS.supports('height', '1dvh')) {
      document.documentElement.style.setProperty('--viewport-height-unit', '1dvh');
    } else {
      document.documentElement.style.setProperty('--viewport-height-unit', '1vh');
    }
  }, []);

  if (isUserLoading && !isOffline) {
    return (
      <div className="base-layout">
        <SplashLoading />
      </div>
    );
  }

  if (!userState) {
    return (
      <div className="base-layout">
        <Outlet />
        <Footer />
        <ToastContainer stacked position={'bottom-center'} autoClose={2000} theme={theme} />
      </div>
    );
  }

  return (
    <div className="base-layout">
      <Outlet />
      <ToastContainer stacked position={'top-center'} autoClose={2000} theme={theme} />
    </div>
  );
};

export default BaseLayout;
