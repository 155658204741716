import { queryErrorEmitter } from '@/lib/http.interceptors';
import React, { useState, useEffect, useContext, PropsWithChildren, useMemo } from 'react';

interface OfflineContextType {
  isOffline: boolean;
  setNetworkAvailability: (status: boolean) => void;
}

const offlineStatusContext = React.createContext<OfflineContextType>({
  isOffline: false,
  setNetworkAvailability: () => {},
});

export const OfflineStatusProvider = ({ children }: PropsWithChildren) => {
  const [browserOfflineStatus, setBrowserOfflineStatus] = useState<boolean>(!navigator.onLine);
  const [networkRequestFailed, setNetworkRequestFailed] = useState<boolean>(false);

  const offlineHandler = () => {
    setBrowserOfflineStatus(true);
  };

  const onlineHandler = () => {
    setBrowserOfflineStatus(false);
  };

  useEffect(() => {
    const unsubscribe = queryErrorEmitter.onQueryError((error) => {
      if (error.maxRetriesReached) {
        setNetworkRequestFailed(true);
      }
    });

    const onSuccessUnsubscribe = queryErrorEmitter.onQuerySuccess(() => {
      setNetworkRequestFailed(false);
    });

    return () => {
      unsubscribe();
      onSuccessUnsubscribe();
    };
  }, []);

  const isOffline = browserOfflineStatus || networkRequestFailed;

  useEffect(() => {
    window.addEventListener('offline', offlineHandler);
    window.addEventListener('online', onlineHandler);

    return () => {
      window.removeEventListener('offline', offlineHandler);
      window.removeEventListener('online', onlineHandler);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      isOffline,
      setNetworkAvailability: setNetworkRequestFailed,
    }),
    [isOffline, setNetworkRequestFailed],
  );

  return <offlineStatusContext.Provider value={contextValue}>{children}</offlineStatusContext.Provider>;
};

export const useOfflineStatus = () => {
  const context = useContext(offlineStatusContext);
  if (context === undefined) {
    throw new Error('useOfflineStatus must be used within a OfflineStatusProvider');
  }
  return context.isOffline;
};

export const useNetworkStatus = () => {
  const context = useContext(offlineStatusContext);
  if (context === undefined) {
    throw new Error('useNetworkStatus must be used within a OfflineStatusProvider');
  }
  return context;
};
