import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WaypointServiceInputItem from './WaypointServiceInputItem';
import { SpecialWaypointServiceItem } from '@/types/common';
import { getGarbageIconLink } from '@/helpers/garbage';
import {
  addUpdateWaypointId,
  removePhotoLog,
  removeUpdateWaypointId,
  selectArrivedPageForm,
  selectArrivedPagePhotosByWaypointId,
  selectIsPhotoUploadingOrDeleting,
  setFormSelectedWaypointIds,
  setFormServiceValue,
  setFormWaypointIdsForPhotoUpload,
  setIsTakePhotoModalVisible,
  toggleSelectedWaypointId,
  useDeleteStatusDataMutation,
} from '@/features/route';
import CheckMark from '@/components/icons/CheckMark';
import CrossMark from '@/components/icons/CrossMark';
import PhotoIcon from '@/components/icons/PhotoIcon';
import NotificationBadgeWrapper from '@/components/elements/NotificationBadgeWrapper';
import useModal from '@/hooks/useModal';
import ImagesModal from '@/components/modals/ImagesModal';
import { selectIsCurrentRouteGroupItemOpenedMode } from '@/features/home';
import ImageGalleryIcon from '@/components/icons/ImageGalleryIcon';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@/components/icons/CancelIcon';
import { WaypointsStatusRequest, waypointsStatusRequestsDb } from '@/database';
import { useCommonItemMaps } from '@/hooks/useCommonItemMaps';
import { WaypointsStatus } from '@/types/waypoints';
import DeleteDataIcon from '@/components/icons/DeleteDataIcon';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import { Waypoint, WaypointType } from '@/features/home';
import PencilIcon from '@/components/icons/PencilIcon';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { socket } from '@/lib/socket';
import { cn } from '@/utils/cn';
import Spinner from '@/components/elements/Spinner';
import { useArrivedPageMergePhotos } from '../../hooks/arrived/useArrivedPageMergePhotos';
import { useAppSelector } from '@/store/types';

interface ArrivedPageWaypointItemProps {
  waypoint: Waypoint<WaypointType.Discharge>;
}

const ArrivedPageWaypointDischargeItem = ({ waypoint }: ArrivedPageWaypointItemProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [tempWaypointFormValues, setTempWaypointFormValues] = useState(null);
  const dispatch = useDispatch();

  const waypointPhotoLogs = useSelector(selectArrivedPagePhotosByWaypointId(waypoint.id));
  const entityIds = React.useMemo(() => [waypoint.id], [waypoint.id]);

  const { mergedPhotos, isFetching: isWaypointPicturesFetching } = useArrivedPageMergePhotos(
    waypointPhotoLogs,
    entityIds,
    WaypointType.Discharge,
  );
  const isPhotoUploadingOrDeleting = useAppSelector(selectIsPhotoUploadingOrDeleting);

  const isRouteGroupItemOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);

  const { t } = useTranslation('tabletPage');

  const [deleteStatusData] = useDeleteStatusDataMutation();
  const isOffline = useOfflineStatus();

  const { selectedWaypointIds, services: formServices } = useSelector(selectArrivedPageForm) ?? {};
  const { isVisible: isImageModalVisible, toggle: toggleImageModal } = useModal();
  const { isVisible: isConfirmationModalVisible, toggle: toggleConfirmationModal } = useModal();
  const { itemTypesMap, garbageMap } = useCommonItemMaps();

  const { objectItem, objectItemDischarge, services } = waypoint;

  const garbage = garbageMap[objectItem.garbageId];
  const itemType = itemTypesMap[objectItem.itemTypeId];

  const isSelected = selectedWaypointIds?.includes(waypoint.id);
  const isFailed = waypoint.done && waypoint.fail;
  const isDone = waypoint.done && !waypoint.fail;

  const isDisableInput = isRouteGroupItemOpenedMode || ((isFailed || isDone) && !isEditMode);

  const handleItemClick = () => {
    if (isDisableInput) {
      return;
    }
    dispatch(toggleSelectedWaypointId(waypoint.id));
  };

  const handleEditClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();

    const isWaypointStatusSavedOnServer = Boolean(waypoint.time);

    const executeWhenEditMode = () => {
      setIsEditMode(false);
      dispatch(setFormSelectedWaypointIds(selectedWaypointIds?.filter((id) => id !== waypoint.id)));
      Object.entries(tempWaypointFormValues).forEach(([serviceId, body]) => {
        const { value } = body as { value: number };
        dispatch(setFormServiceValue({ waypointId: waypoint.id, serviceId, value }));
      });
      setTempWaypointFormValues(null);
    };

    const executeWhenNotEditMode = () => {
      setIsEditMode(true);
      setTempWaypointFormValues(formServices[waypoint.id]);
      dispatch(setFormSelectedWaypointIds([...selectedWaypointIds, waypoint.id]));
    };

    if (isWaypointStatusSavedOnServer && isEditMode) {
      executeWhenEditMode();
      dispatch(removeUpdateWaypointId(waypoint.id));
      return;
    }

    if (isWaypointStatusSavedOnServer && !isEditMode) {
      executeWhenNotEditMode();
      dispatch(addUpdateWaypointId(waypoint.id));
      return;
    }

    if (isEditMode) {
      executeWhenEditMode();
      return;
    }

    executeWhenNotEditMode();
  };

  const handleDeleteDataClick = async () => {
    const payload: WaypointsStatusRequest<WaypointsStatus.DeleteData> = {
      waypointIds: [waypoint.id],
      status: WaypointsStatus.DeleteData,
      body: null,
    };

    if (isOffline) {
      await waypointsStatusRequestsDb.requests.add(payload);
      return;
    }

    await deleteStatusData(payload);
  };

  useEffect(() => {
    socket.on('PhotoDeleted', ({ payload: { waypointIds, filePath } }) => {
      dispatch(removePhotoLog({ waypointIds, filePath }));
    });

    return () => {
      socket.off('PhotoDeleted');
    };
  }, [dispatch]);

  return (
    <>
      <div
        className={cn({
          'arrived-page__list-item': true,
          'arrived-page__list-item--selected': isSelected,
          'arrived-page__list-item--done': isDone,
          'arrived-page__list-item--failed': isFailed,
        })}
        onClick={handleItemClick}
      >
        <div className="gap-4 arrived-page__list-item__title">
          <div className="arrived-page__list-item__item-type">
            <img src={garbage ? getGarbageIconLink(garbage.id) : null} alt="" />
            <div>
              {itemType.capacity > 0 ? (
                <>
                  {itemType.capacity} <small>m3</small>
                </>
              ) : (
                itemType.name
              )}
            </div>
            <div className="arrived-page__list-item__icons">
              {!isRouteGroupItemOpenedMode && waypoint.done && (
                <>
                  <div className="clickable" onClick={() => toggleConfirmationModal()}>
                    <DeleteDataIcon size={'md'} />
                  </div>
                  <div className="clickable" onClick={handleEditClick}>
                    {isEditMode ? <CancelIcon size={'md'} /> : <PencilIcon size={'md'} />}
                  </div>
                </>
              )}
              {isWaypointPicturesFetching ||
                (isPhotoUploadingOrDeleting && (
                  <NotificationBadgeWrapper notificationsAmount={0} size={'sm'}>
                    <Spinner size="sm" />
                  </NotificationBadgeWrapper>
                ))}
              {mergedPhotos?.length > 0 && (
                <div
                  className="clickable"
                  onClick={(event) => {
                    toggleImageModal();
                    event.stopPropagation();
                  }}
                >
                  <NotificationBadgeWrapper notificationsAmount={mergedPhotos.length} size={'sm'}>
                    <ImageGalleryIcon size={'md'} />
                  </NotificationBadgeWrapper>
                </div>
              )}
              <div
                className="clickable"
                onClick={(event) => {
                  if (isDisableInput) {
                    return;
                  }
                  dispatch(setIsTakePhotoModalVisible(true));
                  dispatch(setFormWaypointIdsForPhotoUpload([waypoint.id]));
                  event.stopPropagation();
                }}
              >
                <PhotoIcon size={'md'} />
              </div>
              <div className={isSelected ? 'text-green-500' : ''}>
                {(isSelected || (isDone && isDisableInput)) && <CheckMark size={'md'} />}
                {isFailed && isDisableInput && <CrossMark size={'md'} />}
              </div>
            </div>
          </div>
          <div className="arrived-page__list-item__garbage">{garbage.name}</div>
          {objectItem.objectItemPhotoRequired && (
            <div className="arrived-page__list-item__photo-required">
              <span className={'text-red-500'}>
                <PhotoIcon size={'md'} />
              </span>
              <span>{t('photoIsRequired', { ns: 'arrivedPage' })}</span>
            </div>
          )}
        </div>
        {(objectItem?.driverNotes || objectItemDischarge?.notes || waypoint.hasExtraM3Ordered) && (
          <div className="arrived-page__list-item__driver-notes">
            {objectItem?.driverNotes && objectItem.driverNotes.split('\n').map((note) => <div key={note}>{note}</div>)}
            {objectItemDischarge?.notes && <div>{objectItemDischarge.notes}</div>}
            {waypoint.hasExtraM3Ordered && <div>{t('extraM3')}</div>}
          </div>
        )}
        <div className="arrived-page__list-item__services" onClick={(event) => event.stopPropagation()}>
          {garbage.isTabletWeightManuallySupported && (
            <WaypointServiceInputItem
              key={`${waypoint.id}-manual-weight`}
              service={null}
              articleCode={SpecialWaypointServiceItem.ManualWeight}
              waypointId={waypoint.id}
              isDisabled={isDisableInput}
            />
          )}
          <WaypointServiceInputItem
            key={`${waypoint.id}-dynamic-capacity-or-discharge`}
            service={null}
            articleCode={itemType.isDynamicCapacity ? SpecialWaypointServiceItem.DynamicCapacity : SpecialWaypointServiceItem.Discharge}
            waypointId={waypoint.id}
            isDisabled={isDisableInput}
          />
          {services.map((service) => (
            <WaypointServiceInputItem
              service={service}
              articleCode={service.articleCode}
              waypointId={waypoint.id}
              key={`${service.id}-${waypoint.id}-service`}
              isDisabled={isDisableInput}
            />
          ))}
        </div>
      </div>

      <ImagesModal isVisible={isImageModalVisible} onClose={toggleImageModal} images={mergedPhotos || []} waypointId={waypoint.id} />

      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        hide={toggleConfirmationModal}
        submit={handleDeleteDataClick}
        text={t('clearDataConfirmation', { ns: 'arrivedPage' })}
      />
    </>
  );
};

export default ArrivedPageWaypointDischargeItem;
