import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAdvancedNavigationItems } from '@/contexts/advancedNavigationContext';
import {
  GetWaypointsDto,
  MapSettingsSidebar,
  selectCoordinatesToObject,
  selectDisplayArrivedPage,
  selectIsPhotoPresent,
  setCoordinatesToObject,
  setDisplayArrivedPage,
  setDisplayMapSettings,
  setDisplaySidebarOnMobile,
  toggleDisplayKeysModal,
  toggleMapSettingsDisplayedState,
  updateServiceWaypointsRTKCache,
  updateWaypointsRTKCache,
  useKeysAndGates,
  useWaypointsRequest,
  waypointsApi,
} from '@/features/route';
import ArrivedIcon from '../../../components/icons/ArrivedIcon';
import { useNavigate } from 'react-router-dom';
import GarageIcon from '../../../components/icons/GarageIcon';
import RouteGroupItemStartFinishModal from '../../home/components/RouteGroupItemStartFinishModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckUpModal,
  ExposedCheckUpModalFunctions,
  isRouteTypeWithWaypoints,
  selectIsCurrentRouteGroupItemOpenedMode,
  useAllDeviceTasks,
  useCarCheck,
  useRouteGroupItemsRequest,
  Waypoint,
  WaypointType,
} from '@/features/home';
import { useTranslation } from 'react-i18next';

import ArrivedPage from './ArrivedPage';
import { conditionalClassNames } from '@/helpers/dom';
import { selectSelectedUnloadPoint } from '@/features/unloading';
import KeysModal from '../components/KeysModal';
import OpenGatesActions from '../components/OpenGatesActions';
import WazeButton from '../components/WazeButton';
import UnloadFormSidebar from '../components/UnloadFormSidebar';
import TruckUnloadingIcon from '@/components/icons/TruckUnloadingIcon';
import FuelIcon from '@/components/icons/FuelIcon';
import MapSettingsIcon from '@/components/icons/MapSettingsIcon';
import KeysIcon from '@/components/icons/KeysIcon';
import MailIcon from '@/components/icons/MailIcon';
import { useMessages } from '@/features/messages';
import WaypointsMapListWrapper from '../components/waypoints/WaypointsMapListWrapper';
import WaypointsList from '../components/waypoints/WaypointsList';
import WaypointsUpdatesModalWrapper from '../components/waypoints/WaypointsUpdatesModalWrapper';
import { selectFilteredWaypoints, selectSelectedWaypointIds, setFilters, setSelectedWaypointIds } from '@/store/waypoints/slice';
import { useCoordinatesToNextObject } from '../hooks/useCoordinatesToNextObject';
import { selectUserId } from '@/features/auth';
import { CarCrash } from '@/components/icons/CheckUp/CarCrash';
import { socket } from '@/lib/socket';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { selectIsSyncingEnabled } from '@/store/settings/slice';
import { cn } from '@/utils/cn';
import { SocketEventData } from '@ekt-group/general-purpose-api-interfaces';

export const RoutePage = () => {
  const [isGoToBaseClicked, setIsGoToBaseClicked] = useState(false);
  const [isFinishRouteModalVisible, setIsFinishRouteModalVisible] = useState(false);

  const isPhotoPresent = useAppSelector(selectIsPhotoPresent);
  const isRouteInOpenedMode = useAppSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const displayArrivedPage = useAppSelector(selectDisplayArrivedPage);
  const coordinatesToObject = useAppSelector(selectCoordinatesToObject);
  const selectedUnloadPoint = useAppSelector(selectSelectedUnloadPoint);
  const selectedWaypointIds = useAppSelector(selectSelectedWaypointIds);
  const userId = useAppSelector(selectUserId);

  const { newMessagesCount } = useMessages();
  const { cachedData: waypoints, refetch: refetchWaypoints, isFetching, updateGetWaypointsRTKQuery } = useWaypointsRequest();
  const { setRouteGroupItemAsCurrent, currentRouteGroupItem, changeRouteGroupItemMode, getRouteGroupItemById, reselectWaypointCache } =
    useRouteGroupItemsRequest();

  const filteredWaypoints = useSelector(selectFilteredWaypoints);
  const [isCheckUpModalVisible, setIsCheckUpModalVisible] = useState(false);
  const checkUpModalRef = useRef<ExposedCheckUpModalFunctions>(null);
  const isSyncingEnabled = useAppSelector(selectIsSyncingEnabled);

  const {
    data: tasks,
    isLoading: isTasksLoading,
    status: fetchTasksStatus,
    allDevicePriorityOptions,
  } = useAllDeviceTasks({
    deviceName: currentRouteGroupItem?.car?.carNumber || '',
  });

  const { data: carCheckOptions } = useCarCheck();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation('photoPage');

  const goToArrivedPage = useCallback(() => {
    dispatch(setDisplayArrivedPage(true));
  }, [dispatch]);

  const handleArrivedButtonClick = useCallback(() => {
    goToArrivedPage();

    socket.volatile.emit('ArrivedToWaypoints', {
      type: 'ArrivedToWaypoints',
      payload: {
        waypointIds: selectedWaypointIds,
      },
    });
  }, [goToArrivedPage, selectedWaypointIds]);

  const handleGoToBaseButtonClick = useCallback(() => {
    if (isGoToBaseClicked) {
      setIsFinishRouteModalVisible(true);
      return;
    }

    socket.volatile.emit('NavigatedToBase', { type: 'NavigatedToBase', payload: null });
    setIsGoToBaseClicked(true);
  }, [isGoToBaseClicked]);

  const handleUnloadButtonClick = useCallback(() => {
    socket.volatile.emit('NavigatedToUnloadPoint', { type: 'NavigatedToUnloadPoint', payload: null });
    navigate('/unloading');
  }, [navigate]);

  const selectedWaypoints = useMemo(
    () => (waypoints && waypoints.filter((waypoint) => selectedWaypointIds.includes(waypoint.id))) || [],
    [waypoints, selectedWaypointIds],
  );

  const { setNavigationItems } = useAdvancedNavigationItems();
  const { getCoordinatesToNextObject } = useCoordinatesToNextObject(selectedWaypoints);

  const handleWaypointClick = useCallback(
    async (waypointIds: number[]) => {
      dispatch(setSelectedWaypointIds(waypointIds));
      socket.volatile.emit('WaypointsSelected', { type: 'WaypointsSelected', payload: { waypointIds } });
    },
    [dispatch],
  );

  const handleWaypointStatusChanged = useCallback(
    async (changedWaypointIds: number[]) => {
      if (!changedWaypointIds?.length) {
        return;
      }

      const waypoints = await reselectWaypointCache();

      const changedWaypoints = waypoints?.filter((waypoint) => changedWaypointIds.includes(waypoint.id)) || [];

      // if there is still waypoints in the object, don't close the arrived modal
      const isAllWaypointsDone = changedWaypoints.every((waypoint) =>
        waypoint.type === WaypointType.Discharge ? waypoint.done : Boolean(waypoint.doneAt),
      );

      if (isAllWaypointsDone) {
        dispatch(setDisplayArrivedPage(false));
        dispatch(setSelectedWaypointIds([]));
        return;
      }

      dispatch(setDisplayArrivedPage(true));
    },
    [dispatch, reselectWaypointCache],
  );

  useEffect(() => {
    if ((waypoints && waypoints.length > 0) || !isRouteTypeWithWaypoints(currentRouteGroupItem?.sheetType)) {
      return;
    }

    return () => {
      dispatch(setDisplaySidebarOnMobile(false));
      dispatch(setDisplayMapSettings(false));
      dispatch(setDisplayArrivedPage(false));
      dispatch(setFilters(null));
    };
  }, [currentRouteGroupItem?.sheetType, dispatch, waypoints]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('ArrivedToWaypoints', ({ payload: { waypointIds } }) => {
      toast('Received update from other devices, updating...', {
        type: 'success',
      });

      if (!waypointIds?.length) {
        toast('No waypoints received from tablet', {
          type: 'error',
        });
        return;
      }

      dispatch(setSelectedWaypointIds(waypointIds));

      if (isPhotoPresent) {
        toast('Photo is present, please submit it first', {
          type: 'error',
        });
        return;
      }

      goToArrivedPage();
    });

    return () => {
      socket.off('ArrivedToWaypoints');
    };
  }, [isSyncingEnabled, goToArrivedPage, isPhotoPresent, dispatch]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('RouteHasBeenChanged', async ({ payload: { routeGroupItemId } }) => {
      if (currentRouteGroupItem?.id === routeGroupItemId) {
        return;
      }

      if (isPhotoPresent) {
        toast('Photo is present, please submit it first', {
          type: 'error',
        });
        return;
      }

      toast('Received RouteHasBeenChanged event from server. Changing route...', { type: 'success' });

      dispatch(setSelectedWaypointIds([]));
      dispatch(setDisplayArrivedPage(false));

      const routeGroupItem = getRouteGroupItemById(routeGroupItemId);
      setRouteGroupItemAsCurrent(routeGroupItemId);
      changeRouteGroupItemMode(routeGroupItem);
      navigate(`/route/${routeGroupItemId}`);
    });

    return () => {
      socket.off('RouteHasBeenChanged');
    };
  }, [
    isPhotoPresent,
    setRouteGroupItemAsCurrent,
    changeRouteGroupItemMode,
    getRouteGroupItemById,
    dispatch,
    isSyncingEnabled,
    navigate,
    currentRouteGroupItem?.id,
  ]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('RouteHasBeenStarted', async ({ payload: { routeGroupItemId } }) => {
      setRouteGroupItemAsCurrent(routeGroupItemId);
      if (currentRouteGroupItem?.id === routeGroupItemId) {
        if (!currentRouteGroupItem?.start) {
          toast('Received RouteHasBeenStarted event from server. Starting route...', { type: 'success' });
          dispatch(setDisplayArrivedPage(false));

          navigate(`/route/${routeGroupItemId}`);
        }
        return;
      }
    });

    return () => {
      socket.off('RouteHasBeenStarted');
    };
  }, [dispatch, isSyncingEnabled, setRouteGroupItemAsCurrent, currentRouteGroupItem?.id, currentRouteGroupItem?.start, navigate]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('RouteHasBeenFinished', ({ payload: { routeGroupItemId } }) => {
      toast('Received RouteHasBeenFinished event from server. Finishing route...', { type: 'success' });
      dispatch(setDisplayArrivedPage(false));

      if (currentRouteGroupItem?.id === routeGroupItemId) {
        navigate('/');
      }
    });

    return () => {
      socket.off('RouteHasBeenFinished');
    };
  }, [isSyncingEnabled, dispatch, navigate, currentRouteGroupItem?.id]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('WaypointsSelected', ({ payload: { waypointIds } }) => {
      if (!waypointIds?.length) {
        toast('No waypoints received from tablet', {
          type: 'error',
        });
        return;
      }

      if (isPhotoPresent) {
        toast('Photo is present, please submit it first', {
          type: 'error',
        });
        return;
      }
      dispatch(setSelectedWaypointIds(waypointIds));

      dispatch(setDisplayArrivedPage(false));
    });

    return () => {
      socket.off('WaypointsSelected');
    };
  }, [dispatch, isSyncingEnabled, displayArrivedPage, isPhotoPresent, handleWaypointStatusChanged]);

  useEffect(() => {
    if (!isSyncingEnabled || !currentRouteGroupItem) {
      return;
    }

    const handler = async ({ payload: { waypointIds, data } }: SocketEventData) => {
      if (!waypointIds?.length) {
        toast('No waypoints received from tablet', {
          type: 'error',
        });
        return;
      }

      if (isPhotoPresent) {
        toast('Photo is present, please submit it first', {
          type: 'error',
        });
        return;
      }

      toast('Received update from other devices, updating...', {
        type: 'success',
      });

      const originalArgs: GetWaypointsDto = {
        routeGroupItemIds: [currentRouteGroupItem?.id],
        waypointIds: [],
        isDischargeSheet: currentRouteGroupItem?.isDischargeSheet,
      };

      if (data?.serviceIds) {
        // it's a "service" waypoint
        updateGetWaypointsRTKQuery(data, originalArgs, WaypointType.Service);
        return;
      }

      updateGetWaypointsRTKQuery(data, originalArgs, WaypointType.Discharge);
      handleWaypointStatusChanged(waypointIds);
    };

    socket.on('WaypointsMarkedAsDischarged', handler);
    socket.on('WaypointsMarkedAsNotDischarged', handler);

    return () => {
      socket.off('WaypointsMarkedAsDischarged');
      socket.off('WaypointsMarkedAsNotDischarged');
    };
  }, [
    waypoints,
    handleWaypointStatusChanged,
    isSyncingEnabled,
    dispatch,
    currentRouteGroupItem,
    isPhotoPresent,
    refetchWaypoints,
    updateGetWaypointsRTKQuery,
  ]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('NavigatedToBase', async () => {
      setIsGoToBaseClicked(true);
    });

    return () => {
      socket.off('NavigatedToBase');
    };
  }, [isSyncingEnabled, handleGoToBaseButtonClick]);

  useEffect(() => {
    if (!isSyncingEnabled) {
      return;
    }

    socket.on('NavigatedToUnloadPoint', async () => {
      navigate('/unloading');
    });

    return () => {
      socket.off('NavigatedToUnloadPoint');
    };
  }, [isSyncingEnabled, navigate]);

  useKeysAndGates();

  useEffect(() => {
    if (!displayArrivedPage) {
      setNavigationItems([
        ...(isRouteTypeWithWaypoints(currentRouteGroupItem?.sheetType)
          ? [
              {
                icon: <ArrivedIcon size={'md'} />,
                label: isRouteInOpenedMode ? t('open', { ns: 'common' }) : t('navbar.arrived', { ns: 'tabletPage' }),
                disabled: selectedWaypointIds.length === 0 || Boolean(selectedUnloadPoint),
                action: handleArrivedButtonClick,
              },
            ]
          : []),
        ...(currentRouteGroupItem?.isDischargeSheet
          ? [
              {
                label: t('navbar.goToUnload', { ns: 'tabletPage' }),
                link: '/unloading',
                icon: <TruckUnloadingIcon size="md" />,
                action: handleUnloadButtonClick,
                disabled: isRouteInOpenedMode,
              },
            ]
          : []),
        {
          icon: isGoToBaseClicked ? <ArrivedIcon size={'md'} /> : <GarageIcon size={'md'} />,
          label: isGoToBaseClicked ? t('finish', { ns: 'common' }) : t('navbar.goToBase', { ns: 'tabletPage' }),
          style: isGoToBaseClicked ? 'primary' : 'success',
          disabled: isRouteInOpenedMode,
          action: handleGoToBaseButtonClick,
        },
        {
          label: t('navbar.beginFueling', { ns: 'tabletPage' }),
          link: '/fueling',
          icon: <FuelIcon size={'md'} />,
          action: () => navigate('/fueling'),
          disabled: isRouteInOpenedMode,
        },
        {
          label: t('navbar.mapSettings', { ns: 'tabletPage' }),
          link: '/settings',
          icon: <MapSettingsIcon size={'md'} />,
          action: () => dispatch(toggleMapSettingsDisplayedState()),
        },
        ...(currentRouteGroupItem?.isDischargeSheet
          ? [
              {
                label: t('navbar.keys', { ns: 'tabletPage' }),
                link: '/keys',
                icon: <KeysIcon size={'md'} />,
                action: () => dispatch(toggleDisplayKeysModal()),
              },
            ]
          : []),
        {
          label: t('carProblemsLabel', { ns: 'tabletPage' }),
          icon: <CarCrash className="w-10 h-10 mb-0" />,
          action: () => {
            checkUpModalRef?.current?.resetForm();
            setIsCheckUpModalVisible(true);
          },
          disabled: !currentRouteGroupItem?.carId,
          style: 'danger',
        },
        {
          label: t('messagesLabel', { ns: 'messages' }),
          link: '/messages',
          icon: <MailIcon size={'md'} />,
          notificationsAmount: newMessagesCount,
          action: () => navigate('/messages'),
        },
      ]);
    }
  }, [
    selectedWaypointIds,
    isGoToBaseClicked,
    i18n.resolvedLanguage,
    currentRouteGroupItem?.isDischargeSheet,
    displayArrivedPage,
    selectedUnloadPoint,
    newMessagesCount,
    isRouteInOpenedMode,
    currentRouteGroupItem?.carId,
    currentRouteGroupItem?.sheetType,
    dispatch,
    navigate,
    t,
    setNavigationItems,
    handleArrivedButtonClick,
    handleUnloadButtonClick,
    handleGoToBaseButtonClick,
  ]);

  useEffect(() => {
    const waypointListItemElement = document.querySelector<HTMLDivElement>('.tablet-page__waypoints-list__group__selected');
    const waypointsListElement = document.querySelector('.tablet-page__waypoints-list');

    waypointsListElement?.scrollTo({
      top: waypointListItemElement?.offsetTop - 5,
    });
  }, [selectedWaypointIds, filteredWaypoints]);

  useEffect(() => {
    const setCoordinates = async () => {
      const waypointListItemElement = document.querySelector<HTMLDivElement>('.tablet-page__waypoints-list__group__selected');

      if (Boolean(selectedUnloadPoint) || displayArrivedPage) {
        return;
      }

      if (waypointListItemElement) {
        const coordinates = await getCoordinatesToNextObject();
        dispatch(setCoordinatesToObject(coordinates));
      } else {
        dispatch(setCoordinatesToObject([]));
      }
    };

    setCoordinates();
  }, [dispatch, selectedWaypointIds, displayArrivedPage, selectedUnloadPoint]);

  const handleCancelCheckUp = () => {
    setIsCheckUpModalVisible(false);
  };

  const handleCheckUpSubmit = () => {
    setIsCheckUpModalVisible(false);
  };

  return (
    <>
      <CheckUpModal
        isVisible={isCheckUpModalVisible}
        onCancel={handleCancelCheckUp}
        onAllStepsCompleted={handleCheckUpSubmit}
        ref={checkUpModalRef}
        allDeviceTasks={tasks}
        mode="addProblemOnly"
        filteredPriorityOptions={allDevicePriorityOptions}
        carCheckOptions={carCheckOptions || []}
        routeGroupItem={currentRouteGroupItem}
      />
      <div
        className={cn('tablet-page', {
          hidden: displayArrivedPage,
        })}
      >
        <WaypointsMapListWrapper
          waypoints={filteredWaypoints || waypoints || []}
          selectedWaypointIds={selectedWaypointIds || []}
          coordinatesToObject={coordinatesToObject || []}
          handleWaypointClick={handleWaypointClick}
        />

        {isRouteTypeWithWaypoints(currentRouteGroupItem?.sheetType) && (
          <>
            <WaypointsList onWaypointClick={handleWaypointClick} />
            <WaypointsUpdatesModalWrapper />
            {currentRouteGroupItem?.isDischargeSheet && (
              <>
                <UnloadFormSidebar />
                <KeysModal />
              </>
            )}
          </>
        )}

        <MapSettingsSidebar />

        <RouteGroupItemStartFinishModal
          isVisible={isFinishRouteModalVisible}
          routeGroupItem={currentRouteGroupItem}
          action={'finish'}
          onClose={() => setIsFinishRouteModalVisible(false)}
          onStart={() => {}}
          onFinish={() => {
            socket.volatile.emit('RouteHasBeenFinished', {
              type: 'RouteHasBeenFinished',
              payload: { routeGroupItemId: currentRouteGroupItem?.id },
            });
            navigate('/');
          }}
        />
      </div>

      {isRouteTypeWithWaypoints(currentRouteGroupItem?.sheetType) && <OpenGatesActions selectedWaypointIds={selectedWaypointIds} />}
      {selectedWaypoints && !displayArrivedPage && <WazeButton waypoints={selectedWaypoints} />}
      {displayArrivedPage && (
        <ArrivedPage
          key={selectedWaypoints.map((waypoint) => waypoint.id).join()}
          arrivedWaypoints={selectedWaypoints}
          onArrivedWaypointsStatusChange={handleWaypointStatusChanged}
        />
      )}
    </>
  );
};
