import React, { useEffect } from 'react';
import ModalWrapper from '@/components/modals/ModalWrapper';
import Button from '@/components/elements/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectArrivedPageForm,
  selectIsTakePhotoModalVisible,
  setFormWaypointIdsForPhotoUpload,
  setIsTakePhotoModalVisible,
  toggleWaypointIdForPhotoUpload,
  useUploadWaypointPictureMutation,
  useUploadServiceWaypointPictureMutation,
  setIsPhotoPresent,
  setIsPhotoUploadingOrDeleting,
} from '@/features/route';
import { selectCurrentRouteGroupItem, Waypoint } from '@/features/home';
import WaypointSelectItem from '../../../photo/components/WaypointSelectItem';
import VerticalShadowScroll from '@/components/elements/VerticalShadowScroll';
import { getPhotoUploadingStatus, setPhotoUploadingStatus } from '@/helpers/localStorage/photoUploadingHelper';
import { PhotoUploadingStatus, usePhotoUploadForm } from '@/features/photo';
import { toast } from 'react-toastify';
import { isToday } from '@/helpers/dateHelper';
import { photoDB, PhotoEntity } from '@/database';
import { AppMode } from '@/types/common';
import { selectUserId } from '@/features/auth';
import { useRollbar } from '@rollbar/react';
import { selectMode } from '@/store/settings/slice';
import { PhotoInputWithCoordinates } from '@/features/photo';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { socket } from '@/lib/socket';

interface TakePhotoModalProps {
  arrivedWaypoints: Waypoint[];
}

const TakePhotoModal = ({ arrivedWaypoints }: TakePhotoModalProps) => {
  const dispatch = useDispatch();
  const rollbar = useRollbar();
  const isOffline = useOfflineStatus();

  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const appMode = useSelector(selectMode);
  const isVisible = useSelector(selectIsTakePhotoModalVisible);
  const userId = useSelector(selectUserId);
  const { waypointIdsForPhotoUpload } = useSelector(selectArrivedPageForm) || {};

  const [uploadWaypointPicture] = useUploadWaypointPictureMutation();
  const [uploadServiceWaypointPicture] = useUploadServiceWaypointPictureMutation();

  const { photo, photoPreview, inputRef, uploadPhoto, setPhoto, resetInput } = usePhotoUploadForm({
    onUpload: async (photo: File) => {
      if (!waypointIdsForPhotoUpload?.length) {
        toast(t('selectWaypoint', { ns: 'photoPage' }), {
          type: 'error',
        });
        return;
      }

      dispatch(setIsPhotoPresent(true));

      const photoEntity = currentRouteGroupItem?.isDischargeSheet ? PhotoEntity.DischargeWaypoint : PhotoEntity.ServiceWaypoint;
      const isBasicAppMode = appMode === AppMode.Basic;

      let serverFilePath = '';

      try {
        const startTime = performance.now();

        dispatch(setIsPhotoUploadingOrDeleting(true));

        const response = currentRouteGroupItem?.isDischargeSheet
          ? await uploadWaypointPicture({ picture: photo, waypointIds: waypointIdsForPhotoUpload, isBasicAppMode }).unwrap()
          : await uploadServiceWaypointPicture({
              picture: photo,
              serviceIds: waypointIdsForPhotoUpload,
              routeGroupItemId: currentRouteGroupItem?.id,
            }).unwrap();

        const filePath = response?.[0]?.filePath;
        serverFilePath = filePath;
        socket.volatile.emit('PhotoUploaded', { type: 'PhotoUploaded', payload: { filePath, waypointIds: waypointIdsForPhotoUpload } });
        const endTime = performance.now();

        dispatch(setIsPhotoUploadingOrDeleting(false));

        if (endTime - startTime > 5000) {
          rollbar.warn('[PhotoUploading - Online] Long photo upload time', {
            duration: endTime - startTime,
            currentPhotoStatus: getPhotoUploadingStatus(),
            userId,
          });
        }

        dispatch(setIsPhotoPresent(false));
      } catch (error) {
        console.error(error);
      }

      setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadRequestAddedToDatabase);

      await photoDB.photos.add({
        photo,
        waypointIds: waypointIdsForPhotoUpload,
        routeGroupItemName: currentRouteGroupItem?.name,
        routeGroupName: currentRouteGroupItem?.routeGroup?.name,
        routeGroupItemId: currentRouteGroupItem?.id,
        timestamp: Date.now(),
        entity: photoEntity,
        serverFilePath,
        isBasicAppMode,
      });
    },
  });

  const { t } = useTranslation();

  const handleUpload = () => {
    dispatch(setIsTakePhotoModalVisible(false));
    setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadButtonClicked);

    uploadPhoto();
  };

  const handlePhotoAdded = () => {
    dispatch(setIsPhotoPresent(true));
  };

  const handlePhotoDeleted = () => {
    resetInput?.();
    dispatch(setIsPhotoPresent(false));
    setPhotoUploadingStatus(PhotoUploadingStatus.NotUploading);
  };

  useEffect(() => {
    if (!isVisible) {
      dispatch(setFormWaypointIdsForPhotoUpload([]));
      setPhotoUploadingStatus(PhotoUploadingStatus.NotUploading);
      return;
    }

    setPhotoUploadingStatus(PhotoUploadingStatus.PhotoUploadModalOpened);
  }, [isVisible]);

  return (
    <ModalWrapper isVisible={isVisible} className="overflow-y-auto h-fit">
      <div className={'flex flex-col gap-4'}>
        <PhotoInputWithCoordinates
          arrivedObject={arrivedWaypoints.length ? arrivedWaypoints[0].object : null}
          photo={photo}
          photoPreview={photoPreview}
          setPhoto={setPhoto}
          resetInput={resetInput}
          inputRef={inputRef}
          doAfterCompression={handlePhotoAdded}
        />
        <div className={'flex gap-4'}>
          <Button disabled={!Boolean(photo)} text={t('delete')} color="danger" size="lg" onClick={handlePhotoDeleted} wide={true} />
          <Button disabled={!Boolean(photo)} text={t('submit')} color="success" size="lg" onClick={handleUpload} wide={true} />
        </div>

        <VerticalShadowScroll className="photo__waypoint-items-wrapper max-h-[25vh]">
          {arrivedWaypoints?.map((waypoint) => (
            <div key={waypoint.id} className="take-photo-modal__waypoint">
              <WaypointSelectItem
                waypoint={waypoint}
                isSelected={waypointIdsForPhotoUpload?.includes(waypoint.id)}
                clickHandler={() => dispatch(toggleWaypointIdForPhotoUpload(waypoint.id))}
              />
            </div>
          ))}
        </VerticalShadowScroll>

        <Button
          text={t('close')}
          color="disabled"
          size="lg"
          onClick={() => {
            resetInput();
            dispatch(setIsTakePhotoModalVisible(false));
          }}
          wide={true}
        />
      </div>
    </ModalWrapper>
  );
};

export default TakePhotoModal;
