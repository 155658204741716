import { useDispatch, useSelector } from 'react-redux';
import { setCurrentGarbageIds, setObjectGates } from '@/store/waypoints/slice';
import { useCallback, useEffect, useMemo } from 'react';
import {
  selectGetWaypointsCache,
  useGetWaypointsQuery,
  GetWaypointsDto,
  waypointsApi,
  updateWaypointsRTKCache,
  updateServiceWaypointsRTKCache,
} from '@/features/route';
import { selectCurrentRouteGroupItem, WaypointType } from '@/features/home';
import { useAppDispatch, useAppSelector } from '@/store/types';
import { WaypointsStatusRequest, waypointsStatusRequestsDb } from '@/database';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useLiveQuery } from 'dexie-react-hooks';
import { WaypointsServiceStatusRequest } from '@/database/waypoints-service-status-requests.db';

interface UseWaypointsRequestProps {
  waypointIds?: number[];
}

const getWaypointType = (request: WaypointsStatusRequest<any>): WaypointType => {
  if ('doneAt' in request.body) {
    return WaypointType.Discharge;
  }
  if ('done' in request.body) {
    return WaypointType.Service;
  }
  throw new Error('Unknown waypoint type');
};

export function useWaypointsRequest({ waypointIds }: UseWaypointsRequestProps = {}) {
  const dispatch = useAppDispatch();
  const currentRouteGroupItem = useSelector(selectCurrentRouteGroupItem);
  const args = useMemo(
    () => ({
      routeGroupItemIds: currentRouteGroupItem?.id ? [currentRouteGroupItem.id] : [],
      isDischargeSheet: currentRouteGroupItem?.isDischargeSheet ?? false,
      waypointIds: waypointIds ?? [],
    }),
    [currentRouteGroupItem?.id, currentRouteGroupItem?.isDischargeSheet, waypointIds],
  );

  const isOffline = useOfflineStatus();

  const { data: cachedData } = useAppSelector(selectGetWaypointsCache(args));

  const {
    data,
    currentData,
    isLoading,
    isFetching,
    refetch,
    isUninitialized: isGetWaypointsCacheUninitialized,
  } = useGetWaypointsQuery(args, {
    skip: !currentRouteGroupItem?.id && !waypointIds?.length,
  });

  const pendingWaypointRequests = useLiveQuery(() => waypointsStatusRequestsDb.requests.toArray());

  // change garbageids and objectGates everytime new waypoints are fetched
  useEffect(() => {
    const garbageIds =
      data &&
      data.reduce<number[]>((acc, item) => {
        const garbageId = item.objectItem?.garbageId;
        if (!garbageId || acc.includes(garbageId)) {
          return acc;
        }
        return [...acc, garbageId];
      }, []);

    dispatch(setCurrentGarbageIds(garbageIds));
    data && dispatch(setObjectGates(data));
  }, [data, dispatch]);

  const updateGetWaypointsRTKQuery = useCallback(
    (
      data: WaypointsStatusRequest<any> | WaypointsServiceStatusRequest,
      originalArgs: GetWaypointsDto,
      type: WaypointType.Discharge | WaypointType.Service,
    ) => {
      if (isGetWaypointsCacheUninitialized) {
        return;
      }
      dispatch(
        waypointsApi.util.updateQueryData('getWaypoints', originalArgs, (draft) => {
          if (type === WaypointType.Discharge) {
            updateWaypointsRTKCache(data as WaypointsStatusRequest<any>, draft);
            return;
          }
          updateServiceWaypointsRTKCache(data as WaypointsServiceStatusRequest, draft);
        }),
      );
    },
    [dispatch, isGetWaypointsCacheUninitialized],
  );

  useEffect(() => {
    if (!pendingWaypointRequests?.length) {
      return;
    }

    for (const request of pendingWaypointRequests) {
      if (!request?.body) {
        continue;
      }
      updateGetWaypointsRTKQuery(request, args, getWaypointType(request));
    }
  }, [args, pendingWaypointRequests, updateGetWaypointsRTKQuery]);

  return { data, cachedData, currentData, refetch, isLoading, isFetching, updateGetWaypointsRTKQuery };
}
