import { selectMapSettings } from '@/features/route';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useSelector } from 'react-redux';
import { selectIsCurrentRouteGroupItemOpenedMode, Waypoint } from '@/features/home';
import { useLazyGetOrsRouteQuery } from '../api/route-planner.api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useGeolocation from 'react-hook-geolocation';
import { useCallback, useRef } from 'react';

const CACHED_POSITION_DELAY = 10000;
const TIME_TO_WAIT_FOR_POSITION_UPDATE = 1000;

const isValidCoordinate = (coord: number): boolean => {
  return typeof coord === 'number' && !isNaN(coord) && coord !== null;
};

export function useCoordinatesToNextObject(selectedWaypoints: Waypoint[]) {
  const isOffline = useOfflineStatus();
  const { showNavigationLine } = useSelector(selectMapSettings);
  const isRouteInOpenedMode = useSelector(selectIsCurrentRouteGroupItemOpenedMode);
  const [getOrsRoute] = useLazyGetOrsRouteQuery();
  const { t } = useTranslation('common');
  const lastCoordinates = useRef<[number, number]>();

  const { latitude, longitude, error } = useGeolocation({
    maximumAge: CACHED_POSITION_DELAY,
    timeout: TIME_TO_WAIT_FOR_POSITION_UPDATE,
  });

  const getCoordinatesToNextObject = useCallback(async () => {
    if (isRouteInOpenedMode || !showNavigationLine) {
      return;
    }

    if (error || !isValidCoordinate(longitude) || !isValidCoordinate(latitude)) {
      toast(t('locationNotEnabled'), { type: 'error' });
      return;
    }

    const currentCoordinates: [number, number] = [longitude, latitude];
    const objectItemWithCoordinates = selectedWaypoints.find(({ objectItem }) => objectItem.longitude && objectItem.latitude);
    const targetCoordinates = objectItemWithCoordinates
      ? [objectItemWithCoordinates.objectItem.longitude, objectItemWithCoordinates.objectItem.latitude]
      : [selectedWaypoints[0]?.object.longitude, selectedWaypoints[0]?.object.latitude];

    if (targetCoordinates?.some((coord) => !coord)) {
      return [];
    }

    if (
      lastCoordinates.current &&
      Math.abs(lastCoordinates.current[0] - currentCoordinates[0]) < 0.0001 &&
      Math.abs(lastCoordinates.current[1] - currentCoordinates[1]) < 0.0001
    ) {
      return [lastCoordinates.current, targetCoordinates];
    }

    lastCoordinates.current = currentCoordinates;

    if (isOffline) {
      return [currentCoordinates, targetCoordinates];
    }

    const { data: orsRouteData } = await getOrsRoute({
      start: currentCoordinates,
      end: targetCoordinates,
    });

    if (!orsRouteData) {
      return [currentCoordinates, targetCoordinates];
    }

    return orsRouteData.features[0].geometry.coordinates;
  }, [error, getOrsRoute, isOffline, isRouteInOpenedMode, latitude, longitude, selectedWaypoints, showNavigationLine, t]);

  return { getCoordinatesToNextObject };
}
