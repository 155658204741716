import { useLiveQuery } from 'dexie-react-hooks';
import { PhotoDbRequest, PhotoDbRequestAction, photoRequestsDb, photoDB } from '@/database';
import { useOfflineStatus } from '@/contexts/offlineStatusContext';
import { useDispatch } from 'react-redux';
import { removePhotoLog, useDeleteWaypointPictureMutation } from '@/features/route';
import { useProcessor } from './useProcessor';

export function usePhotoDbRequestsProcessor() {
  const requests = useLiveQuery(() => photoRequestsDb.requests.toArray());
  const photos = useLiveQuery(() => photoDB.photos.toArray());

  const dispatch = useDispatch();
  const isOffline = useOfflineStatus();
  const [deletePicture] = useDeleteWaypointPictureMutation();

  const processRequest = async ({ action, waypointIds, photoPath, photoId }: PhotoDbRequest) => {
    switch (action) {
      case PhotoDbRequestAction.Delete: {
        if (photoId) {
          const [photoToDelete] = photos?.filter((photo) => photo.id === photoId);

          if (photoToDelete?.waypointIds?.length) {
            photoToDelete.waypointIds = photoToDelete?.waypointIds?.filter((id) => !waypointIds?.includes(id)) || [];
          }

          if (!photoToDelete?.waypointIds?.length) {
            photoToDelete?.id && photoDB.photos.delete(photoToDelete.id);
          } else {
            photoToDelete?.id && photoDB.photos.update(photoToDelete.id, { waypointIds: photoToDelete.waypointIds });
          }
        }

        if (!isOffline && photoPath) {
          await deletePicture({ waypointIds, filePath: photoPath });
          dispatch(removePhotoLog({ waypointIds, filePath: photoPath }));
        }

        break;
      }
      case PhotoDbRequestAction.Upload: {
        // TODO: move uploading logic from photo db processor to here
        break;
      }
      default: {
        throw new Error(`Unknown request action: ${action}`);
      }
    }
  };

  const deleteRequest = (requestId: number) => photoRequestsDb.requests.delete(requestId);

  useProcessor<PhotoDbRequest>({
    dbItems: requests,
    processDbItem: processRequest,
    deleteDbItem: deleteRequest,
    rollbarErrorMessage: 'Processing photo db request failed',
  });
}
